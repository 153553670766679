import { Action } from '@ngrx/store';

export const enum GENERATE_PREVIEW_ACTIONS {
  GENERATE_PREVIEW = '[Generate Preview] Generate Preview',
  PREVIEW_GENERATED = '[Generate Preview] Preview Generated',
  PREVIEW_GENERATION_FAIL = '[Generate Preview] Fail Preview Generation'
}

export class GeneratePreviewAction {
  readonly type = GENERATE_PREVIEW_ACTIONS.GENERATE_PREVIEW;

  constructor(public payload: { uid: string }) {}
}

export interface ApplicationPreviewEvent {
  type: 'event';
  issue: 'application_preview_created';
  data: ApplicationPreviewData;
  context: {
    subdom: string;
    userid: string;
    application_guid: string;
  };
}

export interface ApplicationPreviewData {
  status: 'generated';
  download_link: string;
}

export class PreviewGeneratedAction implements Action {
  readonly type = GENERATE_PREVIEW_ACTIONS.PREVIEW_GENERATED;

  constructor(
    public payload: { uid: string; preview: ApplicationPreviewData }
  ) {}
}

export class PreviewGenerationFailAction implements Action {
  readonly type = GENERATE_PREVIEW_ACTIONS.PREVIEW_GENERATION_FAIL;

  constructor(public payload: { uid: string; error: { status: 'failed' } }) {}
}

export type PreviewGenerationAction =
  | GeneratePreviewAction
  | PreviewGeneratedAction
  | PreviewGenerationFailAction;
