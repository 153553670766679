import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../shared';

export function validateTrue(c: AbstractControl): ValidationResult {
  const val = c.value;
  if (val === true) {
    return null;
  }

  return {
    true: true
  };
}
