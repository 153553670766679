export interface TwilioToken {
  token: string;
}

export enum CallDirection {
  Inbound = 'inbound',
  OutboundApi = 'outbound-api',
  OutboundDial = 'outbound-dial'
}

export enum CallState {
  Ready = 'ready',
  Closed = 'closed',
  Connecting = 'connecting',
  Open = 'open',
  Pending = 'pending',
  Reconnecting = 'reconnecting',
  Ringing = 'ringing',
  Error = 'error'
}

export enum CallEvent {
  Accept = 'accept',
  Ringing = 'ringing',
  Cancel = 'cancel',
  Disconnect = 'disconnect',
  Error = 'error',
  Mute = 'mute',
  Unmute = 'unmute',
  Reconnected = 'reconnected',
  Reconnecting = 'reconnecting',
  Reject = 'reject'
}

export interface TwilioPhoneNumberCapabilities {
  voice: boolean;
  SMS: boolean;
  MMS: boolean;
}

export interface TwilioPhoneNumber {
  friendlyName: string;
  phoneNumber: string;
  locality: string;
  rateCenter: string;
  latitude: string;
  longitude: string;
  region: string;
  postalCode: string;
  isoCountry: string;
  capabilities: TwilioPhoneNumberCapabilities;
}
