import { AppEnvironment, isDevelopment } from '../env';

export const oldSitesRootDomain = 'sparksites.io';

export function noIndexSite(hostname: string) {
  return (
    hostname.endsWith(`.${oldSitesRootDomain}`) || hostname.endsWith('.451.io')
  );
}

export const ROBOTS_NO_INDEX_TOKEN = 'robotsNoIndex';

function createSiteUrl(type: 'app451' | 'page451' | 'microsite451') {
  return ({
    subdomain,
    domain,
    guid
  }: {
    subdomain: string;
    domain: string;
    guid: string;
  }) => {
    if (domain === oldSitesRootDomain) {
      const subdom = guid.split('.')[0];
      switch (type) {
        case 'app451':
          return `${subdomain}.${subdom}.app.${domain}`;
        case 'page451':
          return `${subdomain}.${subdom}.page.${domain}`;
        case 'microsite451':
          return `${subdomain}.${subdom}.site.${domain}`;
      }
    }
    return `${subdomain}.${domain}`;
  };
}

export const createPageUrl = createSiteUrl('page451');
export const createAppUrl = createSiteUrl('app451');
export const createMicrositeUrl = createSiteUrl('microsite451');

export const sitesRootDomains = {
  prod: {
    dashboard451: 'dashboard451.sites.451.io',
    event451: 'event451.sites.451.io',
    page451: 'page451.sites.451.io',
    app451: 'app451.sites.451.io',
    microsite451: 'microsite451.sites.451.io',
    appointment451: 'appointment451.sites.451.io',
    portal451: 'portal451.sites.451.io',
    embedForms: 'embed-forms.451.io'
  },
  dev: {
    dashboard451: 'dashboard451.sites-dev.451.io',
    event451: 'event451.sites-dev.451.io',
    page451: 'page451.sites-dev.451.io',
    app451: 'app451.sites-dev.451.io',
    microsite451: 'microsite451.sites-dev.451.io',
    appointment451: 'appointment451.sites-dev.451.io',
    portal451: 'portal451.sites-dev.451.io',
    embedForms: 'embed-forms.dev.451.io'
  }
};

export const getAppointmentUrl = (
  env: AppEnvironment,
  subdom: string
): string => {
  if (isDevelopment(env)) {
    return `https://${sitesRootDomains.dev.appointment451}`;
  }
  return `https://${subdom}.${sitesRootDomains.prod.appointment451}`;
};
