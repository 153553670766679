import { provideTranslocoScope } from '@jsverse/transloco';

const langs = {
  en: {
    ceeb: {
      emptyState: {
        title: 'School not selected yet',
        description:
          'If your school is not in our database, please type the full name of your school in the name field.'
      }
    },
    multipleSelect: {
      addBtn: 'Add {{item}}'
    },
    multipleText: {
      addBtn: 'Add {{item}}'
    },
    password: {
      shown: 'Show Password',
      hidden: 'Hide Password'
    },
    errors: {
      accepted: "Must be a positive value like 'yes','on','1'",
      afterDate: '{{srcDate}} must be after {{dstDate}}',
      afterField: '{{srcFieldDate}} must be after {{dstFieldDate}}',
      alpha: 'Can contain only letters',
      alphaDash: 'Can contain only letters and underscore or dash',
      alphaNum: 'Can contain only letters and numbers',
      alphaSpace: 'Can contain only letters and spaces',
      beforeDate: '{{srcDate}} must be before {{dstDate}}',
      beforeField: '{{srcFieldDate}} must be before {{dstFieldDate}}',
      between: 'Number must be between {{min}} and {{max}}',
      boolean: "Must be a boolean like value [0,1,'1','2','',true,false]",
      confirmed: 'Must be confirmed',
      dateFormat: 'Date must be in format {{format}}',
      different: 'Must be different then {{dstValue}}',
      digits: 'Must have number of digits between {{min}} and {{max}}',
      digitsBetween: 'Must be between {{min}} and {{max}} digits.',
      email: 'Must be a valid email format',
      emailConfirmation: 'Both email and email confirmation must match',
      emailVerify: 'Must be a valid email',
      in: 'Value must be in {{list}}',
      integer: 'Must be an integer.',
      ip: 'Field must be a valid ip address.',
      maxLength: 'Must have maximum {{length}} characters',
      maxNumber: 'Maximum number is {{max}}',
      minLength: 'Must have minimum {{length}} characters',
      minNumber: 'Minimum number is {{min}}',
      name: 'Can contain only letters, numbers, spaces and interpunction',
      notIn: 'Value cannot be one of this values: {{list}}',
      numeric: 'Must be numeric value',
      password:
        'Password must be at least 8 characters and contain at least one uppercase, lowercase, number and special character (#?!@$%^&*-)',
      phone: 'Must be a valid phone number',
      phoneVerify: 'Must be a valid phone number',
      regex: 'Must be in format {{expr}}',
      required: 'Field is required',
      requiredIf: 'Field is required',
      requiredWith: 'Field is required',
      requiredWithAll: 'Field is required',
      requiredWithout: 'Field is required',
      requiredWithoutAll: 'Field is required',
      requiredCeeb: 'Must select an institution from dropdown',
      same: "Must match '{{dstValue}}'",
      mask: 'Must match the format {{requiredMask}}',
      size: 'Field size needs to be at least {{length}} characters',
      ssn: 'Must be a valid SSN format',
      string: 'Field must be a string.',
      url: 'Must be a valid url',
      zipcode: 'Must be a valid zipcode format',
      true: 'Must be checked',
      matDatepickerParse: 'Must be a valid date'
    }
  },
  es: {
    ceeb: {
      emptyState: {
        title: 'No ha seleccionado una Escuela',
        description:
          'Si su Escuela no está en nuestra base de datos, por favor escriba el nombre completo de su escuela en el campo Nombre.'
      }
    },
    multipleSelect: {
      addBtn: 'Agregar {{item}}'
    },
    multipleText: {
      addBtn: 'Agregar {{item}}'
    },
    password: {
      shown: 'Mostrar Contraseña',
      hidden: 'Ocultar Contraseña'
    },
    errors: {
      accepted: "Debe ser un valor positivo como 'si','encendido','1'",
      afterDate: '{{srcDate}} debe ser posterior a {{dstDate}}',
      afterField: '{{srcFieldDate}} debe ser posterior a {{dstFieldDate}}',
      alpha: 'Puede contener solo letras',
      alphaDash: 'Puede contener solo letras, guiones y guines bajos',
      alphaNum: 'Puede contener solo letras y números',
      alphaSpace: 'Puede contener solo letras y espacios',
      beforeDate: '{{srcDate}} debe ser anterior a {{dstDate}}',
      beforeField: '{{srcFieldDate}} debe ser anterior a {{dstFieldDate}}',
      between: 'El número debe estar entre {{min}} y {{max}}',
      boolean: "Debe ser un valor booleano como [0,1,'1','2','',true,false]",
      confirmed: 'Debe ser confirmado',
      dateFormat: 'La fecha debe estar en formato {{format}}',
      different: 'Debe ser diferente a {{dstValue}}',
      digits: 'Debe tener un número de dígitos entre {{min}} y {{max}}',
      digitsBetween: 'Debe tener entre {{min}} y {{max}} dígitos.',
      email: 'Debe tener un formato de correo electrónico válido',
      emailConfirmation:
        'Ambos, correo electrónico y confirmación de correo electrónico deben coincidir',
      emailVerify: 'Debe ser un correo eletrónico válido',
      in: 'El valor debe estar en {{list}}',
      integer: 'Debe ser un número entero.',
      ip: 'El valor debe ser una dirección IP válida.',
      maxLength: 'Debe tener máximo {{length}} caracteres',
      maxNumber: 'El número máximo es {{max}}',
      minLength: 'Debe tener mínimo {{length}} caracteres',
      minNumber: 'El número mínimo es {{min}}',
      name: 'Puede contener solo letras, números, espacios y signos de puntuación',
      notIn: 'El valor no puede ser ninguno de estos valores: {{list}}',
      numeric: 'Debe ser un valor numérico',
      password:
        'La contraseña debe tener al menos 8 caracteres y contener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial (#?!@$%^&*-)',
      phone: 'Debe ser un número de teléfono válido',
      phoneVerify: 'Debe ser un número de teléfono válido',
      regex: 'Debe estar en formato {{expr}}',
      required: 'El campo es requerido',
      requiredIf: 'El campo es requerido',
      requiredWith: 'El campo es requerido',
      requiredWithAll: 'El campo es requerido',
      requiredWithout: 'El campo es requerido',
      requiredWithoutAll: 'El campo es requerido',
      requiredCeeb: 'El campo es requerido',
      same: "Debe coincidir con '{{dstValue}}'",
      mask: 'Debe coincidir con el formato {{requiredMask}}',
      size: 'El tamaño del campo debe tener al menos {{length}} caracteres',
      ssn: 'Debe tener un formato de Número de Seguro Social válido',
      string: 'El campo debe ser texto.',
      url: 'Debe ser una URL válida',
      zipcode: 'Debe tener un formato de código postal válido',
      true: 'Debe estar marcado',
      matDatepickerParse: 'Debe ser una fecha válida'
    }
  }
};

export const translationsProvider = provideTranslocoScope({
  scope: 'forms451',
  loader: {
    en: () =>
      new Promise(resolve => {
        resolve(langs.en);
      }),
    es: () =>
      new Promise(resolve => {
        resolve(langs.es);
      })
  }
});
