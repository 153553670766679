export enum AIRequestType {
  GetCampaignEmailSubjectLines = 'get-campaign-email-subject-lines',
  GetImprovedCampaignEmailSubjectLines = 'get-improved-campaign-email-subject-lines',
  GetCampaignEmailPreviewTexts = 'get-campaign-email-preview-texts',
  GetImprovedCampaignEmailPreviewTexts = 'get-improved-campaign-email-preview-texts',
  GenerateConversationSubjectLine = 'generate-conversation-subject-line',
  SummarizeConversation = 'summarize-conversation',
  SentimentAnalysis = 'sentiment-analysis',
  Chatbot = 'chatbot',

  ChatbotPostprocessSms = 'chatbot-postprocess-sms',
  ChatbotPostprocessWhatsApp = 'chatbot-postprocess-whatsapp',
  ChatbotPostprocessEmail = 'chatbot-postprocess-email',

  ConversationsShortenText = 'conversations-shorten-text',
  ConversationsExpandText = 'conversations-expand-text',
  ConversationsImproveWriting = 'conversations-improve-writing',
  ConversationsChangeTone = 'conversations-change-tone',

  ConversationsShortenTextSms = 'conversations-shorten-text-sms',
  ConversationsExpandTextSms = 'conversations-expand-text-sms',
  ConversationsImproveWritingSms = 'conversations-improve-writing-sms',
  ConversationsChangeToneSms = 'conversations-change-tone-sms',

  PagesShortenText = 'pages-shorten-text',
  PagesExpandText = 'pages-expand-text',
  PagesImproveWriting = 'pages-improve-writing',
  PagesChangeTone = 'pages-change-tone',
  PagesGenerateHeadline = 'pages-generate-headline',

  CampaignsShortenText = 'campaigns-shorten-text',
  CampaignsExpandText = 'campaigns-expand-text',
  CampaignsImproveWriting = 'campaigns-improve-writing',
  CampaignsChangeTone = 'campaigns-change-tone',

  FixSpellingAndGrammar = 'fix-spelling-and-grammar',
  Translate = 'translate',

  CopilotPagesChatbotOutline = 'copilot-pages-chatbot-outline',
  CopilotPagesChatbotCreateComponents = 'copilot-pages-chatbot-create-components',
  CopilotPagesChatbotCreateComponent = 'copilot-pages-chatbot-create-component',
  CopilotPagesChatbotCreateBillboardCtaComponent = 'copilot-pages-chatbot-create-billboard-cta-component',
  CopilotPagesChatbotCreateBillboardFormComponent = 'copilot-pages-chatbot-create-billboard-form-component',
  CopilotPagesChatbotCreateFooterComponent = 'copilot-pages-chatbot-create-footer-component',
  CopilotPagesChatbotCreateVideoComponent = 'copilot-pages-chatbot-create-video-component',
  CopilotPagesChatbotCreateImageComponent = 'copilot-pages-chatbot-create-image-component',
  CopilotPagesChatbotCreateGalleryComponent = 'copilot-pages-chatbot-create-gallery-component',
  CopilotPagesChatbotCreateMapComponent = 'copilot-pages-chatbot-create-map-component',
  CopilotPagesChatbotCreateQuoteComponent = 'copilot-pages-chatbot-create-quote-component',
  CopilotPagesChatbotCreateDividerComponent = 'copilot-pages-chatbot-create-divider-component',
  CopilotPagesChatbotCreateIntroComponent = 'copilot-pages-chatbot-create-intro-component',
  CopilotPagesChatbotCreateCtaComponent = 'copilot-pages-chatbot-create-cta-component',
  CopilotPagesChatbotCreateTeaserComponent = 'copilot-pages-chatbot-create-teaser-component',
  CopilotPagesChatbotCreateSmartTeaserComponent = 'copilot-pages-chatbot-create-smart-teaser-component',
  CopilotPagesChatbotCreateFeatureTeaserComponent = 'copilot-pages-chatbot-create-feature-teaser-component',
  CopilotPagesChatbotSearchKnowledgeBase = 'copilot-pages-chatbot-search-knowledge-base',

  CopilotCampaignsChatbotOutline = 'copilot-campaigns-chatbot-outline',
  CopilotCampaignsChatbotTemplate = 'copilot-campaigns-chatbot-template',
  CopilotCampaignsChatbotSearchKnowledgeBase = 'copilot-campaigns-chatbot-search-knowledge-base'
}

export interface AIUserContext {
  id?: string | null;
  name?: string | null;
}

export enum TranslateLanguage {
  English = 'English',
  MandarinChinese = 'Mandarin Chinese',
  Spanish = 'Spanish',
  Hindi = 'Hindi',
  StandardArabic = 'Standard Arabic',
  French = 'French',
  Bengali = 'Bengali',
  Portugese = 'Portugese',
  Russian = 'Russian',
  Japanese = 'Japanese',
  Urdu = 'Urdu',
  Korean = 'Korean',
  Vietnamese = 'Vietnamese',
  German = 'German',
  Italian = 'Italian'
}

export enum AiDataLayerItemType {
  StudentConversationProfile = 'student_conversation_profile'
}

export type AiDataLayerItemContent = StudentConversationProfile; // add more types as needed

export interface AiDataLayerItem {
  guid: string;
  subdom: string;
  user_id: string;
  type: AiDataLayerItemType;
  created_at: string;
  updated_at: string;
  content: AiDataLayerItemContent;
}

export enum TonePreference {
  Formal = 'formal',
  Casual = 'casual',
  Neutral = 'neutral'
}

export interface StudentConversationProfile {
  interests?: string[];
  concerns_and_questions?: string[];
  background_and_preferences?: string[];
  additional_information?: string[];
  main_language?: string;
  engagement_patterns?: string[];
  academic_goals?: string[];
  frequently_used_keywords?: string[];
  tone_preference?: TonePreference;
}

export enum LlmModel {
  GPT_4O = 'gpt-4o',
  GPT_4O_MINI = 'gpt-4o-mini',
  GEMINI_2_FLASH = 'gemini-2-flash'
}
