import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Self,
  SkipSelf,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { PhoneApiService } from '@element451-libs/api451';
import { Countries } from '@element451-libs/common451';
import { Forms451Api, I18N } from '@element451-libs/models451';
import { getNameFromCountryCode } from '@element451-libs/utils451/pipes';
import { keys } from 'lodash';
import {
  Subscription,
  asyncScheduler,
  delay,
  filter,
  map,
  of,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs';
import { EventBusService } from '../../../../event-bus';
import { DynamicFieldModel, DynamicGroupModel } from '../../../../models';
import { findControl, findModel } from '../../../../shared';
import { validatePhone } from '../../../../validation';
import { FieldConfigDirective } from '../../../shared';
import { FIELDS } from '../../../shared/fields';
import { GroupComponent } from '../group';

const countryCodeSuffix = '-country_code';
const countryAlpha2Suffix = '-country_alpha_2';
const phoneNumberSuffix = '-number';

// eslint-disable-next-line prefer-const
let codes = [];

@Component({
  selector: 'lum-df-phone, elm-df-phone',
  templateUrl: './phone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [EventBusService]
})
export class PhoneComponent
  extends FieldConfigDirective<DynamicGroupModel>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(GroupComponent, { static: true }) groupComponent: GroupComponent;

  private _listenCountryCodeChanges: Subscription;
  private _countryCodeModel: DynamicFieldModel;
  private _phoneNumberModel: DynamicFieldModel;

  private _countryCodeKey: string;
  private _phoneNumberKey: string;

  private _countryAlpha2Key: string;

  private eventSub: Subscription;

  get groupNode() {
    return this.fieldControl as FormGroup;
  }

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(LOCALE_ID) private locale: I18N.SupportedLocale,
    private phoneApi: PhoneApiService,
    @SkipSelf() private globalEventBus: EventBusService,
    @Self() private localEventBus: EventBusService,
    private cd: ChangeDetectorRef
  ) {
    super(localEventBus);
  }

  ngOnInit() {
    this.setModels();
    this.adjustPhoneNumberModelType();

    if (this._countryCodeModel) {
      this.generatePhoneCodes();
      this._countryCodeModel.optionChanges.next(codes);
    }

    this.eventSub = this.localEventBus
      .on('*')
      .pipe(filter(({ eventName }) => eventName !== 'onBlur'))
      .subscribe(({ eventName, data }) =>
        this.globalEventBus.send(eventName, data)
      );

    const listenOnBlur: Subscription = this.localEventBus
      .on('onBlur')
      .pipe(
        switchMap(e => {
          if (this.hasPhoneVerificationEnabled()) {
            const { phoneNumber } = this.findControls();

            if (!phoneNumber.value) {
              const errors = phoneNumber.errors;
              if (errors && errors.phoneVerify) {
                delete errors.phoneVerify;
                phoneNumber.setErrors(errors);
              }
              return of(e);
            }

            return this.verifyPhone().pipe(
              tap(response => {
                if (response.valid) {
                  phoneNumber.setErrors(null);
                } else {
                  phoneNumber.setErrors({ phoneVerify: true });
                }
                phoneNumber.markAsDirty();
                phoneNumber.markAsTouched();
                this.groupComponent.cd.markForCheck();
              }),
              filter(response => response.valid),
              map(() => e)
            );
          }
          return of(e);
        })
      )
      .subscribe(() => {
        this.emitData();
      });

    this.eventSub.add(listenOnBlur);
  }

  ngAfterViewInit() {
    if (this.model.isHidden) {
      return;
    }

    const { phoneNumber, countryAlpha2, countryCode } = this.findControls();

    if (
      !this._phoneNumberModel.isHidden &&
      phoneNumber &&
      !phoneNumber.hasValidator(validatePhone)
    ) {
      phoneNumber.addValidators(validatePhone);
      phoneNumber.updateValueAndValidity({ emitEvent: false });
    }

    if (!this._countryCodeModel.isHidden && countryCode && countryAlpha2) {
      this._listenCountryCodeChanges = countryCode.valueChanges.subscribe(
        dialCode => {
          let a2 = '';
          // TODO: figure out in the future how to differntiate between US and Canada
          if (dialCode === '1') {
            a2 = 'US';
          } else {
            a2 = Countries.getA2CodeByDialCode('+' + dialCode);
          }
          countryAlpha2.setValue(a2, {
            onlySelf: true
          });
        }
      );
    }
  }

  ngOnDestroy() {
    if (this._listenCountryCodeChanges) {
      this._listenCountryCodeChanges.unsubscribe();
    }
    if (this.eventSub) {
      this.eventSub.unsubscribe();
    }
  }

  private emitData() {
    const value = {};

    const controls = (this.group.controls[this.model.key] as FormGroup)
      .controls;

    const ks = keys(controls);
    for (const k of ks) {
      const control = controls[k];

      // ignore invalid fields when doing partial saves, to avoid weird validation errors
      // if (control.valid) {
      value[k] = control.value;
      // }
    }

    this.globalEventBus.send('onBlur', {
      name: this.model.key,
      value
    });
  }

  private hasPhoneVerificationEnabled() {
    return this.model.validations?.some(
      v => v.type === Forms451Api.ValidationType.PhoneVerify
    );
  }

  private verifyPhone() {
    const { countryCode, phoneNumber } = this.findControls();
    return this.phoneApi
      .verify(countryCode.value || '', phoneNumber.value || '')
      .pipe(
        switchMap(response => {
          // if async validator for phone verification on the phone has not yet finished
          // we want to wait for it to stabilize
          if (this.groupNode.status === 'PENDING') {
            return this.groupNode.statusChanges.pipe(
              startWith(this.groupNode.status),
              filter(status => status !== 'PENDING'),
              take(1),
              map(() => response),
              delay(0, asyncScheduler) // wait until everything stabilizes in the next event loop
            );
          }
          return of(response);
        })
      );
  }

  private setModels() {
    this._countryCodeKey = this.model.name + countryCodeSuffix;
    this._countryAlpha2Key = this.model.name + countryAlpha2Suffix;
    this._phoneNumberKey = this.model.name + phoneNumberSuffix;

    this._countryCodeModel = findModel(this.model, this._countryCodeKey);
    this._phoneNumberModel = findModel(this.model, this._phoneNumberKey);

    // if phone number is not hidden, then country code should not be hidden (unless it has a default value)
    if (!this._phoneNumberModel.isHidden && !this._countryCodeModel.default) {
      this._countryCodeModel.isHidden = false;
    }
  }

  private findControls() {
    const countryCode = findControl(
      this.group,
      this._countryCodeKey
    ) as UntypedFormControl;

    const phoneNumber = findControl(
      this.group,
      this._phoneNumberKey
    ) as UntypedFormControl;

    const countryAlpha2 = findControl(
      this.group,
      this._countryAlpha2Key
    ) as UntypedFormControl;

    return {
      countryCode,
      phoneNumber,
      countryAlpha2
    };
  }

  private adjustPhoneNumberModelType() {
    this._phoneNumberModel.type = FIELDS.PHONE_NUMBER;
  }

  private generatePhoneCodes() {
    if (codes.length > 0) {
      return;
    }
    for (const country of Countries.getCountryCodesJson()) {
      const name = getNameFromCountryCode(country.code, this.locale);
      const flag = Countries.getFlagFromA2Code(country.code) || '';
      const dial = country.dial_code;
      // we reverse the order so that we can search countries by name
      const template = `
<span style="display: flex">
<span style="order: 1">${name} </span>
<span style="order: 0">${flag} (${dial}) </span>
</span>
`.replace(/\n/g, '');

      const option = {
        text: this.sanitizer.bypassSecurityTrustHtml(template),
        value:
          country.dial_code.indexOf('+') > -1
            ? country.dial_code.slice(1)
            : country.dial_code,
        checked: false
      };
      codes.push(option);
    }
  }
}
