import { ApplicationsApi } from '@element451-libs/models451';
import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { produce } from 'immer';
import * as fromAccount from '../account/account.actions';
import { ACCOUNT_ACTIONS } from '../account/account.actions';
import { selectApp } from '../app.feature';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { DASHBOARD_ACTIONS } from '../dashboard/dashboard.actions';
import {
  USER_APPLICATIONS_ACTIONS,
  UserApplicationsAction
} from './user-applications.actions';
import { Application } from './user-applications.models';

export interface UserApplicationsState extends EntityState<Application> {
  loaded: boolean;
  loading: boolean;
  dashboardLoading: boolean;
  dashboardLoaded: boolean;
  activeRegistrationId: string;
  loadedByRegistrationId: Record<string, boolean>;
}

export const userApplicationsFeature = 'userApplications';

const selectId = (app: Application) => app.registration_id;

const adapter = createEntityAdapter<Application>({
  selectId: selectId,
  sortComparer: false
});

const initialState = adapter.getInitialState({
  loaded: false,
  loading: false,
  dashboardLoading: false,
  dashboardLoaded: false,
  activeRegistrationId: null,
  // used for situations where we need to know if a specific registration has been loaded
  // useful for waiting for applications to load after a user registered for a specific application
  loadedByRegistrationId: {}
});

export function userApplicationsReducer(
  state: UserApplicationsState = initialState,
  action:
    | UserApplicationsAction
    | fromAccount.AccountAction
    | fromDashboard.ApplicationSubmittedAction
    | fromDashboard.DeclineOfferSuccessAction
): UserApplicationsState {
  switch (action.type) {
    case USER_APPLICATIONS_ACTIONS.LOAD_USER_APPLICATIONS_REQUEST: {
      return produce(state, draft => {
        if (!draft.loaded) {
          draft.loading = true;
        }
        if (action.payload.registrationId) {
          draft.loadedByRegistrationId[action.payload.registrationId] = false;
        }
      });
    }

    case USER_APPLICATIONS_ACTIONS.LOAD_USER_APPLICATIONS_SUCCESS: {
      state = adapter.setAll(action.payload.normalized, state);

      return produce(state, draft => {
        draft.loading = false;
        draft.loaded = true;
        if (action.payload.registrationId) {
          draft.loadedByRegistrationId[action.payload.registrationId] = true;
        }
      });
    }

    case USER_APPLICATIONS_ACTIONS.LOAD_USER_APPLICATIONS_FAIL: {
      return produce(state, draft => {
        draft.loading = false;
        if (action.payload.registrationId) {
          draft.loadedByRegistrationId[action.payload.registrationId] = false;
        }
      });
    }

    case USER_APPLICATIONS_ACTIONS.OPENED_APPLICATION:
      return { ...state, activeRegistrationId: action.payload.registrationId };

    case USER_APPLICATIONS_ACTIONS.SELECT_REGISTRATION:
      return { ...state, activeRegistrationId: action.payload };

    case ACCOUNT_ACTIONS.SIGN_OUT:
      return initialState;

    case DASHBOARD_ACTIONS.APPLICATION_SUBMITTED: {
      const update: Update<Application> = {
        id: action.payload.registrationId,
        changes: {
          status: ApplicationsApi.ApplicationState.Submitted,
          decision_status: {
            slug: ApplicationsApi.ApplicationStatusType.Submitted,
            name: 'Submitted'
          }
        }
      };
      return adapter.updateOne(update, state);
    }

    case DASHBOARD_ACTIONS.DECLINE_OFFER_SUCCESS: {
      const update: Update<Application> = {
        id: action.payload.registrationId,
        changes: {
          status: ApplicationsApi.ApplicationState.Submitted,
          decision_status: {
            slug: ApplicationsApi.ApplicationStatusType.Withdrawn,
            name: 'Withdrawn'
          }
        }
      };
      return adapter.updateOne(update, state);
    }
    default:
      return state;
  }
}

export const selectUserApplicationsFeature =
  createFeatureSelector<UserApplicationsState>(userApplicationsFeature);

export const selectState = createSelector(
  selectApp,
  selectUserApplicationsFeature
);

export const selectLoaded = createSelector(selectState, state => state.loaded);

export const selectLoading = createSelector(
  selectState,
  state => state.loading
);

export const selectActiveRegistrationId = createSelector(
  selectState,
  state => state.activeRegistrationId
);

export const selectLoadedByRegistrationId = (registrationId: string) =>
  createSelector(
    selectState,
    state => state.loadedByRegistrationId[registrationId]
  );

export const {
  selectIds: selectUserApplicationIds,
  selectEntities: selectUserApplicationEntities,
  selectAll: selectAllUserApplications
} = adapter.getSelectors(selectState);
