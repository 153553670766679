import { size, toUpper, trim, uniqBy, uniqueId } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  ConditionalOperator,
  IConditional,
  IField,
  IFieldOption,
  IFilter,
  IValidation
} from '../../api-data';
import { FIELDS } from '../../components/shared/fields';
import { findAutocompleteBestMatch, getFieldOptions } from '../../helpers';
import { DynamicGroupModel } from '../group';

/*
 *
 * This class is used for representing parsed
 * form data field objects
 * it is used as an adapter
 * so we don't have to change
 * functionality api later
 * when the data needs to be changed
 * we can just adjust it
 *
 */
export class DynamicFieldModel {
  // view data
  label?: string;
  placeholder?: string;
  hint?: string | null;
  isHidden?: boolean;
  newLine?: boolean;
  order?: number;
  size?: string;
  type: string;
  name?: string;
  inputWidget?: string;

  /** only markdown fields */
  markdown?: string;

  /** only email fields  */
  hasEmailConfirmation?: boolean;

  /** only file fields */
  documentTypeGuid?: string;

  // data
  options?: IFieldOption[];
  // to support reactive option changes
  optionChanges: BehaviorSubject<IFieldOption[]> = new BehaviorSubject([]);
  get options$(): Observable<IFieldOption[]> {
    return this.optionChanges.asObservable();
  }

  multiple?: boolean;
  disabled?: boolean;

  // business logic data
  default?: string;
  slug?: string;
  validations?: IValidation[];
  conditionals?: IConditional[];
  conditionalOperator: ConditionalOperator;

  filtersOperator?: ConditionalOperator;
  selfFilters?: IFilter;
  targetFilters?: IFilter;

  conditionsPassed?: boolean;
  conditionalVisible?: Subject<boolean>;
  conditionRecords: object = {};

  // internal module logic
  // this will be used for searching through model tree and for identifying controls in the angulars form group
  key: string;

  parent: DynamicGroupModel | null;

  autocomplete: string;

  // whatever extra information is needed to be passed with the model itself
  context?: object;

  constructor(config: IField = {} as any) {
    // initialize view data
    this.type = determineType((config = config || ({} as any)));
    this.label = trim(config.label) || '';
    this.placeholder = config.placeholder;
    this.hint = config.help_text;
    this.isHidden = !!config.hidden;
    this.newLine = !!config.new_line;
    this.size = config.size;
    this.order = +config.index_weight || 0;
    this.multiple = config.multiple || false;
    this.conditionalVisible = new BehaviorSubject(true);
    this.default = config.default || null;
    this.conditionalOperator = config.conditionals_operator || '$or';
    this.filtersOperator = config.filters_operator || '$or';
    this.inputWidget = config.input_widget;

    this.disabled = config.disabled || false;
    this.markdown = config.value;
    this.documentTypeGuid = config.document_type_guid;

    this.hasEmailConfirmation = config.email_confirmation;

    // initialize data
    this.options = getFieldOptions(config);
    this.optionChanges.next(this.options);

    // initialize business logic data
    this.slug = config.slug;
    this.name = config.name;

    this.validations = uniqBy(config.validations, v => v.type);
    this.conditionals = config.conditionals || [];

    this.selfFilters = config.filters || null;
    this.targetFilters = config.target_filters || null;

    this.context = config.context;

    if (this.selfFilters && this.targetFilters) {
      console.warn(`
                Warning:
                Filters and reverse filters where specified on the same field.
                This can lead to unpredictable behavior.
            `);
    }

    // initialize internal module logic
    this.key = config.name || uniqueId('lum-df-key');
    this.autocomplete = findAutocompleteBestMatch(this.key);
  }

  addParent(parent: DynamicGroupModel) {
    this.parent = parent;
  }

  /**
   * on clear hook in the event system,
   * gets attached during render process
   * calling this on model gets propagated through all UI layers
   */
  onClear = () => {};
  /**
   * on blur hook in the event system,
   * gets attached during render process
   * calling this on model gets propagated through all UI layers
   */
  onBlur = () => {};
}

export function determineType(config: IField): FIELDS {
  const { type, autocomplete, input_widget } = config;

  if (!type) {
    return FIELDS.NONE;
  } else if (isMultipleTextField(config)) {
    return FIELDS.MULTIPLE_TEXT;
  } else if (isMultipleSelectField(config)) {
    return FIELDS.MULTIPLE_SELECT;
  } else if (isMaskField(config)) {
    return FIELDS.MASK;
  } else if (type === 'boolean') {
    return FIELDS.BOOLEAN;
  } else if (type === 'email') {
    return FIELDS.EMAIL;
  } else if (type === 'select' && input_widget === 'force_select') {
    return FIELDS.AUTOCOMPLETE_SELECT;
  } else if (type === 'select' && autocomplete) {
    return FIELDS.AUTOCOMPLETE_SELECT;
  } else if (type === 'select' && input_widget === 'toggle') {
    return FIELDS.RADIO_TOGGLE;
  } else if (type === 'radio' && input_widget === 'toggle') {
    return FIELDS.RADIO_TOGGLE;
  } else if (type === 'checkbox' && isMultipleWithOptions(config)) {
    if (input_widget === 'toggle') {
      return FIELDS.CHECKBOX_TOGGLE;
      /*
       * Checkbox displayed as multi select field
       * Checkboxes are used for multi select, as that field type has support on BE
       * for segment filters and import/export logic and handling multiple values
       */
    } else if (input_widget === 'select_multiple') {
      return FIELDS.SELECT_MULTIPLE;
    } else {
      return FIELDS.CHECKBOX_MULTIPLE;
    }
  } else if (type === 'checkbox') {
    return FIELDS.CHECKBOX;
  } else if (type === 'file' && input_widget === 'file_sync') {
    return FIELDS.FILE_SYNC;
  } else if (input_widget === 'honey_pot') {
    return FIELDS.HONEY_POT;
  } else if (type === 'multi_file' && input_widget === 'file_sync') {
    return FIELDS.MULTI_FILE_SYNC;
  } else if (type === 'multi_file') {
    return FIELDS.MULTI_FILE;
  } else if (type === 'audio_video' && input_widget === 'file_sync') {
    return FIELDS.AUDIO_VIDEO_SYNC;
  } else if (type === 'audio_video') {
    return FIELDS.AUDIO_VIDEO;
  }

  return toUpper(config.type) as FIELDS;
}

function isMultipleWithOptions(config: IField): boolean {
  return !!config.options || !!config.data_source_settings;
}

export function isMarkdownModel(model: DynamicFieldModel) {
  return model.type === FIELDS.MARKDOWN;
}

export function isModelWithoutValidations(model: DynamicFieldModel) {
  return isMarkdownModel(model);
}

export function isDateModel(model: DynamicFieldModel) {
  return model.type === FIELDS.DATEPICKER;
}

export function isHoneyPotModel(model: DynamicFieldModel) {
  return model.type === FIELDS.HONEY_POT;
}

export function isFileSyncModel(model: DynamicFieldModel) {
  return model.type === FIELDS.FILE_SYNC;
}

function isMaskField(config: IField) {
  return (
    config.type === 'text' &&
    size(config.validations) > 0 &&
    config.validations.some(validation => validation.type === 'mask')
  );
}

function isMultipleTextField(config: IField) {
  return config.type === 'text' && config.multiple === true;
}

function isMultipleSelectField(config: IField) {
  return config.type === 'select' && config.multiple === true;
}
