import { coerceNumberProperty } from '@angular/cdk/coercion';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { isNil, keys } from 'lodash';

export interface CollectionSearch<C = any> {
  /**
   * q is deprecated (will still be used on older API calls)
   */
  q?: string;
  offset?: number;
  limit?: number;
  sort?: string;
  item?: CollectionSearchItem;
  [key: string]: any;
}

export interface CollectionSearchItem {
  search_input_filter?: { value: string };
  [filter_key: string]: { value?: any };
}

export function searchToParams(cs: CollectionSearch): HttpParams {
  const seed = new HttpParams();
  return keys(cs).reduce(
    (params, key) =>
      !isNil(cs[key]) ? params.set(key, cs[key] as string) : params,
    seed
  );
}

export function queryParamsToSearch(queryParams: Params): CollectionSearch {
  const { offset, limit, ...rest } = queryParams;

  return {
    limit: coerceNumberProperty(limit, null),
    offset: coerceNumberProperty(offset, null),
    ...rest
  };
}
