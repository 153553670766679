<div class="elm-pg-container" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div class="elm-pg-above-overlay">
    <div
      class="text-content"
      [ngClass]="{
        'align-left': aligned === 'left',
        'align-right': aligned === 'right',
        'align-center': aligned === 'center'
      }"
    >
      <h2
        class="lum-display-2 elm-dynamic-wysiwyg-link-color-wrapper"
        [ngClass]="{
          'lum-fg-dark-gray': isLightTheme,
          'lum-fg-white': isDarkTheme
        }"
        editable="title"
        *ngIf="elements.title"
        [innerHTML]="title | elmSafeHtml"
      ></h2>

      <h4
        class="lum-display-4 elm-dynamic-wysiwyg-link-color-wrapper"
        [ngClass]="{
          'lum-fg-dark-gray': isLightTheme,
          'lum-fg-white': isDarkTheme
        }"
        editable="subtitle"
        *ngIf="elements.subtitle"
        [innerHTML]="subtitle | elmSafeHtml"
      ></h4>

      <hr *ngIf="elements.divider" [ngStyle]="{ 'background-color': separatorColor }" />

      <p
        class="lum-body-1 elm-dynamic-wysiwyg-link-color-wrapper"
        [ngClass]="{
          'lum-fg-normal-gray': isLightTheme,
          'lum-fg-light-gray': isDarkTheme
        }"
        editable="text"
        *ngIf="elements.text"
        [innerHTML]="text | elmSafeHtml"
      ></p>
    </div>
  </div>
</div>
