import { Forms451Api } from '@element451-libs/models451';
import { groupBy, values } from 'lodash';

export const toItem = <T>(data: T) => ({ item: JSON.stringify(data) });
export const toPlainItem = <T>(data: T) => ({ item: data });
export const toEncoded = <T>(param: string, data: T) =>
  `${param}=${encodeURIComponent(JSON.stringify(data))}`;
export const toEncodedItem = <T>(data: T) => toEncoded('item', data);

export function createFormDataFromFiles(files: Forms451Api.FileUpload[]) {
  const formData = new FormData();

  if (!files || files.length === 0) {
    return formData;
  }

  const groupedFiles = values(groupBy(files, file => file.key));
  groupedFiles.forEach(fileOrMultipleFiles => {
    if (fileOrMultipleFiles.length === 1) {
      const file = fileOrMultipleFiles[0];
      formData.set(file.key, file.file);
    } else {
      fileOrMultipleFiles.forEach(file => {
        formData.append(file.key, file.file);
      });
    }
  });

  return formData;
}
