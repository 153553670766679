import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { each, isArray, isNumber, isString } from 'lodash';
import { IFile } from '../api-data';
import { IFieldOption, IFieldWithData } from '../api-data/field';
import { FIELDS } from '../components/shared/fields';
import {
  DynamicFieldModel,
  DynamicFormModel,
  DynamicGroupModel
} from '../models';
import { findControl, findModel } from '../shared/util';

@Injectable({
  providedIn: 'root'
})
export class PrepopulateVisitor {
  visit(
    fields: IFieldWithData[],
    formGroup: UntypedFormGroup,
    formModel: DynamicFormModel
  ) {
    each(fields, fieldWithData => {
      if (
        !fieldWithData ||
        // undefined allows prepopulating fields without clearing local values from other fields
        (!fieldWithData.subfields && fieldWithData.value === undefined)
      )
        return;

      const key = fieldWithData.key || fieldWithData.name;
      const node = findControl(formGroup, key);
      const model = findModel(formModel, key);

      if (!model || !node) return;

      switch (model.type) {
        case FIELDS.GROUP:
        case FIELDS.CEEB:
        case FIELDS.ADDRESS:
        case FIELDS.PHONE:
        case FIELDS.COUNSELOR:
          this.visitGroupField(
            fieldWithData,
            node as UntypedFormGroup,
            model as DynamicGroupModel
          );
          break;

        case FIELDS.DATEPICKER:
          this.visitDateField(fieldWithData, node, model);
          break;

        case FIELDS.NONE:
          break;

        case FIELDS.CHECKBOX_MULTIPLE:
        case FIELDS.CHECKBOX_TOGGLE:
          this.visitCheckboxMultiple(
            fieldWithData,
            node as UntypedFormGroup,
            model
          );
          break;

        case FIELDS.FILE:
        case FIELDS.FILE_SYNC:
        case FIELDS.AUDIO_VIDEO:
        case FIELDS.AUDIO_VIDEO_SYNC:
          this.visitFileField(fieldWithData, node, model);
          break;

        case FIELDS.MULTI_FILE:
        case FIELDS.MULTI_FILE_SYNC:
          this.visitMultiFileField(fieldWithData, node, model);
          break;

        case FIELDS.MULTIPLE_SELECT:
        case FIELDS.MULTIPLE_TEXT:
          this.visitRegularField(fieldWithData, node, model);
          break;

        case FIELDS.SELECT_MULTIPLE:
          this.visitRegularField(fieldWithData, node, model);
          break;

        /**
         * special case on app451 for prepopulating application scoped conditional fields, in this case we dont know the fields type
         */
        case FIELDS.APPLICATION_CONDITIONAL_FIELD_TARGET:
          this.visitApplicationConditionalFieldTarget(
            fieldWithData,
            node,
            model
          );
          break;

        default:
          this.visitRegularField(fieldWithData, node, model);
          break;
      }
    });
  }

  visitRegularField(
    fieldWithData: IFieldWithData,
    control: AbstractControl,
    model: DynamicFieldModel
  ) {
    control.patchValue(fieldWithData.value);
  }

  visitFileField(
    fieldWithData: IFieldWithData,
    control: AbstractControl,
    model: DynamicFieldModel
  ) {
    let value = null;
    if (fieldWithData.value && fieldWithData.value.files) {
      value = fieldWithData.value.files;
    } else if (fieldWithData.value) {
      value = fieldWithData.value;
    } else if (fieldWithData.files) {
      value = fieldWithData.files;
    }

    control.setValue(value);
  }

  visitMultiFileField(
    fieldWithData: IFieldWithData<{ files: IFile[] | null } | null>,
    control: AbstractControl,
    model: DynamicFieldModel
  ) {
    /**
     * possible values:
     * - null
     * - { files: null }
     * - { files: [] }
     * - { files: [File, File] }
     * - string[]
     *
     * we should set non-empty File[] or null for correct rendering
     */
    if (fieldWithData.value?.files?.length) {
      control.setValue(fieldWithData.value.files);
    } else if (
      isArray(fieldWithData.value) &&
      isString(fieldWithData.value[0])
    ) {
      control.setValue(fieldWithData.value);
    } else {
      control.setValue(null);
    }
  }

  visitGroupField(
    fieldWithData: IFieldWithData,
    control: UntypedFormGroup,
    model: DynamicGroupModel
  ) {
    // This is to support repeaters as regular fields
    // we need to save index in the model
    // so that we can pull it out later when form is saved
    // so we dont create new item if it already exists
    const index = fieldWithData.weight;
    if (isNumber(index)) {
      if (control.contains('weight')) {
        control.get('weight').setValue(index);
      } else {
        control.addControl('weight', new UntypedFormControl(index));
      }
    }

    this.visit(fieldWithData.subfields, control, model);
  }

  visitDateField(
    fieldWithData: IFieldWithData,
    control: AbstractControl,
    model: DynamicFieldModel
  ) {
    control.patchValue(
      fieldWithData.value ? new Date(fieldWithData.value) : null
    );
  }

  visitCheckboxMultiple(
    fieldWithData: IFieldWithData,
    control: UntypedFormGroup,
    model: DynamicFieldModel
  ) {
    const formValue = checkboxOptionsToFormValue(
      model.options,
      fieldWithData.value
    );
    control.patchValue(formValue);
  }

  visitApplicationConditionalFieldTarget(
    fieldWithData: IFieldWithData,
    control: AbstractControl,
    model: DynamicFieldModel
  ) {
    // adds handling for multiple checkbox to adapt its value
    // fix for https://linear.app/element451/issue/PRL-1253/conditional-logic-applications-bug
    if (isArray(fieldWithData.value)) {
      const value: { [key: string]: boolean } = {};
      fieldWithData.value.map(v => (value[v] = true));
      control.patchValue(value);
    } else {
      this.visitRegularField(fieldWithData, control, model);
    }
  }
}

function checkboxOptionsToFormValue(
  options: IFieldOption[],
  values: string[]
): { [key: string]: boolean } {
  const value: { [key: string]: boolean } = {};

  for (const option of options) {
    if (isArray(values)) {
      value[option.value] = !!values.includes(option.value);
    }
    // TEMP: hotfix until data is cleaned up
    if (isString(values) || isNumber(values)) {
      // we need double equals to capture 3 == '3'
      // eslint-disable-next-line eqeqeq
      value[option.value] = values == option.value;
    }
  }

  return value;
}
