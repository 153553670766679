import {
  Content,
  Conversation,
  ErrorObject,
  FunctionCallOutputItemType,
  Item,
  MessageItemType,
  ResponseCreateConfig,
  Session,
  Usage,
  WebSocketEvent
} from './realtime-api.models';

// Utility types to extract nested values
type NestedValueOf<T> = T extends string
  ? T
  : T extends object
    ? NestedValueOf<T[keyof T]>
    : never;

// Client Event Types
export const Client = {
  Session: {
    Update: 'session.update'
  },
  InputAudioBuffer: {
    Append: 'input_audio_buffer.append',
    Commit: 'input_audio_buffer.commit',
    Clear: 'input_audio_buffer.clear'
  },
  Conversation: {
    Item: {
      Create: 'conversation.item.create',
      Truncate: 'conversation.item.truncate',
      Delete: 'conversation.item.delete'
    }
  },
  Response: {
    Create: 'response.create',
    Cancel: 'response.cancel'
  }
} as const;

type ClientValue = NestedValueOf<typeof Client>;

// Server Event Types
export const Server = {
  Error: 'error',
  Session: {
    Created: 'session.created',
    Updated: 'session.updated'
  },
  Conversation: {
    Created: 'conversation.created',
    Item: {
      Created: 'conversation.item.created',
      InputAudioTranscription: {
        Completed: 'conversation.item.input_audio_transcription.completed',
        Failed: 'conversation.item.input_audio_transcription.failed'
      },
      Truncated: 'conversation.item.truncated',
      Deleted: 'conversation.item.deleted'
    }
  },
  InputAudioBuffer: {
    Committed: 'input_audio_buffer.committed',
    Cleared: 'input_audio_buffer.cleared',
    SpeechStarted: 'input_audio_buffer.speech_started',
    SpeechStopped: 'input_audio_buffer.speech_stopped'
  },
  Response: {
    Created: 'response.created',
    Done: 'response.done',
    OutputItem: {
      Added: 'response.output_item.added',
      Done: 'response.output_item.done'
    },
    ContentPart: {
      Added: 'response.content_part.added',
      Done: 'response.content_part.done'
    },
    Text: {
      Delta: 'response.text.delta',
      Done: 'response.text.done'
    },
    AudioTranscript: {
      Delta: 'response.audio_transcript.delta',
      Done: 'response.audio_transcript.done'
    },
    Audio: {
      Delta: 'response.audio.delta',
      Done: 'response.audio.done'
    },
    FunctionCallArguments: {
      Delta: 'response.function_call_arguments.delta',
      Done: 'response.function_call_arguments.done'
    }
  },
  RateLimits: {
    Updated: 'rate_limits.updated'
  }
} as const;

type ServerValue = NestedValueOf<typeof Server>;

// Custom Event Types
export const Custom = {
  UiDisplayManager: 'ui_display_manager',
  ApiError: 'api_error',
  Media: 'media'
} as const;

type CustomValue = NestedValueOf<typeof Custom>;

function eventsToEnum(
  obj: typeof Server | typeof Client | typeof Custom
): Set<string> {
  const result = new Set<string>();

  function recurse(o: typeof Server | typeof Client | typeof Custom): void {
    for (const key in o) {
      const value = o[key];
      if (typeof value === 'object') {
        recurse(value);
      } else if (typeof value === 'string') {
        result.add(value);
      }
    }
  }

  recurse(obj);
  return result;
}

export const ServerEvents = eventsToEnum(Server);
export const ClientEvents = eventsToEnum(Client);
export const CustomEvents = eventsToEnum(Custom);

// Base interface for all events
interface BaseEvent<T extends string> {
  event_id?: string; // Optional client-generated ID
  type: T;
}

// Client Events
export interface SessionUpdateEvent
  extends BaseEvent<typeof Client.Session.Update> {
  session: Session;
}

interface InputAudioBufferAppendEvent
  extends BaseEvent<typeof Client.InputAudioBuffer.Append> {
  audio: string; // Base64-encoded audio bytes
}

type InputAudioBufferCommitEvent = BaseEvent<
  typeof Client.InputAudioBuffer.Commit
>;

type InputAudioBufferClearEvent = BaseEvent<
  typeof Client.InputAudioBuffer.Clear
>;

export interface ConversationItemCreateEvent
  extends BaseEvent<typeof Client.Conversation.Item.Create> {
  previous_item_id?: string | null;
  item: FunctionCallOutputItemType | MessageItemType;
}

interface ConversationItemTruncateEvent
  extends BaseEvent<typeof Client.Conversation.Item.Truncate> {
  item_id: string;
  content_index: number;
  audio_end_ms: number;
}

interface ConversationItemDeleteEvent
  extends BaseEvent<typeof Client.Conversation.Item.Delete> {
  item_id: string;
}

interface ResponseCreateEvent extends BaseEvent<typeof Client.Response.Create> {
  response?: ResponseCreateConfig;
}

type ResponseCancelEvent = BaseEvent<typeof Client.Response.Cancel>;

// Server Events

interface ErrorEvent extends BaseEvent<typeof Server.Error> {
  error: ErrorObject;
}

interface SessionCreatedEvent extends BaseEvent<typeof Server.Session.Created> {
  session: Session;
}

interface SessionUpdatedEvent extends BaseEvent<typeof Server.Session.Updated> {
  session: Session;
}

interface ConversationCreatedEvent
  extends BaseEvent<typeof Server.Conversation.Created> {
  conversation: Conversation;
}

interface ConversationItemCreatedEvent
  extends BaseEvent<typeof Server.Conversation.Item.Created> {
  previous_item_id?: string | null;
  item: Item;
}

interface ConversationItemInputAudioTranscriptionCompletedEvent
  extends BaseEvent<
    typeof Server.Conversation.Item.InputAudioTranscription.Completed
  > {
  item_id: string;
  content_index: number;
  transcript: string;
}

interface ConversationItemInputAudioTranscriptionFailedEvent
  extends BaseEvent<
    typeof Server.Conversation.Item.InputAudioTranscription.Failed
  > {
  item_id: string;
  content_index: number;
  error: ErrorObject;
}

interface ConversationItemTruncatedEvent
  extends BaseEvent<typeof Server.Conversation.Item.Truncated> {
  item_id: string;
  content_index: number;
  audio_end_ms: number;
}

interface ConversationItemDeletedEvent
  extends BaseEvent<typeof Server.Conversation.Item.Deleted> {
  item_id: string;
}

interface InputAudioBufferCommittedEvent
  extends BaseEvent<typeof Server.InputAudioBuffer.Committed> {
  previous_item_id?: string;
  item_id: string;
}

type InputAudioBufferClearedEvent = BaseEvent<
  typeof Server.InputAudioBuffer.Cleared
>;

export interface InputAudioBufferSpeechStartedEvent
  extends BaseEvent<typeof Server.InputAudioBuffer.SpeechStarted> {
  audio_start_ms: number;
  item_id: string;
}

interface InputAudioBufferSpeechStoppedEvent
  extends BaseEvent<typeof Server.InputAudioBuffer.SpeechStopped> {
  audio_end_ms: number;
  item_id: string;
}

interface ResponseCreatedEvent
  extends BaseEvent<typeof Server.Response.Created> {
  response: {
    id: string;
    object: string; // "realtime.response"
    status: string; // e.g., "in_progress"
    status_details?: string | null;
    output: Item[];
    usage?: Usage | null;
  };
}

interface ResponseDoneEvent extends BaseEvent<typeof Server.Response.Done> {
  response: {
    id: string;
    object: string; // "realtime.response"
    status: string; // e.g., "completed"
    status_details?: string | null;
    output: Item[];
    usage?: Usage | null;
  };
}

interface ResponseOutputItemAddedEvent
  extends BaseEvent<typeof Server.Response.OutputItem.Added> {
  response_id: string;
  output_index: number;
  item: Item;
}

interface ResponseOutputItemDoneEvent
  extends BaseEvent<typeof Server.Response.OutputItem.Done> {
  response_id: string;
  output_index: number;
  item: Item;
}

interface ResponseContentPartAddedEvent
  extends BaseEvent<typeof Server.Response.ContentPart.Added> {
  response_id: string;
  item_id: string;
  output_index: number;
  content_index: number;
  part: Content;
}

interface ResponseContentPartDoneEvent
  extends BaseEvent<typeof Server.Response.ContentPart.Done> {
  response_id: string;
  item_id: string;
  output_index: number;
  content_index: number;
  part: Content;
}

interface ResponseTextDeltaEvent
  extends BaseEvent<typeof Server.Response.Text.Delta> {
  response_id: string;
  item_id: string;
  output_index: number;
  content_index: number;
  delta: string;
}

interface ResponseTextDoneEvent
  extends BaseEvent<typeof Server.Response.Text.Done> {
  response_id: string;
  item_id: string;
  output_index: number;
  content_index: number;
  text: string;
}

interface ResponseAudioTranscriptDeltaEvent
  extends BaseEvent<typeof Server.Response.AudioTranscript.Delta> {
  response_id: string;
  item_id: string;
  output_index: number;
  content_index: number;
  delta: string;
}

interface ResponseAudioTranscriptDoneEvent
  extends BaseEvent<typeof Server.Response.AudioTranscript.Done> {
  response_id: string;
  item_id: string;
  output_index: number;
  content_index: number;
  transcript: string;
}

interface ResponseAudioDeltaEvent
  extends BaseEvent<typeof Server.Response.Audio.Delta> {
  response_id: string;
  item_id: string;
  output_index: number;
  content_index: number;
  delta: string; // Base64-encoded audio data delta
}

interface ResponseAudioDoneEvent
  extends BaseEvent<typeof Server.Response.Audio.Done> {
  response_id: string;
  item_id: string;
  output_index: number;
  content_index: number;
}

interface ResponseFunctionCallArgumentsDeltaEvent
  extends BaseEvent<typeof Server.Response.FunctionCallArguments.Delta> {
  response_id: string;
  item_id: string;
  output_index: number;
  call_id: string;
  delta: string; // JSON string
}

export interface ResponseFunctionCallArgumentsDoneEvent
  extends BaseEvent<typeof Server.Response.FunctionCallArguments.Done> {
  name: string;
  response_id: string;
  item_id: string;
  output_index: number;
  call_id: string;
  arguments: string; // Final JSON string
}

interface RateLimitInfo {
  name: string;
  limit: number;
  remaining: number;
  reset_seconds: number;
}

interface RateLimitsUpdatedEvent
  extends BaseEvent<typeof Server.RateLimits.Updated> {
  rate_limits: RateLimitInfo[];
}

export enum RealtimeEventSource {
  Client = 'client',
  Server = 'server',
  Custom = 'custom'
}

export enum UiDisplayManagerElement {
  Link = 'link'
}

export interface UiDisplayManagerEvent
  extends BaseEvent<typeof Custom.UiDisplayManager> {
  element: UiDisplayManagerElement;
  title: string;
  content: string;
}

export interface ApiErrorEvent extends BaseEvent<typeof Custom.ApiError> {
  error: string;
  details: string;
}

export interface MediaEvent extends BaseEvent<typeof Custom.Media> {
  audio: string;
}

export type CustomEvents = UiDisplayManagerEvent | ApiErrorEvent | MediaEvent;

export type ClientEvent =
  | SessionUpdateEvent
  | InputAudioBufferAppendEvent
  | InputAudioBufferCommitEvent
  | InputAudioBufferClearEvent
  | ConversationItemCreateEvent
  | ConversationItemTruncateEvent
  | ConversationItemDeleteEvent
  | ResponseCreateEvent
  | ResponseCancelEvent;

export type ServerEvent =
  | ErrorEvent
  | SessionCreatedEvent
  | SessionUpdatedEvent
  | ConversationCreatedEvent
  | ConversationItemCreatedEvent
  | ConversationItemInputAudioTranscriptionCompletedEvent
  | ConversationItemInputAudioTranscriptionFailedEvent
  | ConversationItemTruncatedEvent
  | ConversationItemDeletedEvent
  | InputAudioBufferCommittedEvent
  | InputAudioBufferClearedEvent
  | InputAudioBufferSpeechStartedEvent
  | InputAudioBufferSpeechStoppedEvent
  | ResponseCreatedEvent
  | ResponseDoneEvent
  | ResponseOutputItemAddedEvent
  | ResponseOutputItemDoneEvent
  | ResponseContentPartAddedEvent
  | ResponseContentPartDoneEvent
  | ResponseTextDeltaEvent
  | ResponseTextDoneEvent
  | ResponseAudioTranscriptDeltaEvent
  | ResponseAudioTranscriptDoneEvent
  | ResponseAudioDeltaEvent
  | ResponseAudioDoneEvent
  | ResponseFunctionCallArgumentsDeltaEvent
  | ResponseFunctionCallArgumentsDoneEvent
  | RateLimitsUpdatedEvent;

export type Event = ClientEvent | ServerEvent | CustomEvents;

export type EventType =
  | ClientValue
  | ServerValue
  | CustomValue
  | WebSocketEvent;
