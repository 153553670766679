import { Injectable } from '@angular/core';
import { ApplicationsApi } from '@element451-libs/api451';
import { Store, select } from '@ngrx/store';
import * as actions from './user-info-requests.actions';
import * as selectors from './user-info-requests.reducer';

@Injectable()
export class UserInfoRequestsService {
  loading$ = this.store.pipe(select(selectors.selectLoading));

  loaded$ = this.store.pipe(select(selectors.selectLoaded));

  submitting$ = this.store.pipe(select(selectors.selectSubmitting));

  requests$ = this.store.pipe(select(selectors.selectAll));

  hasUserInfoRequests$ = this.store.pipe(select(selectors.hasUserInfoRequests));

  requestTypeCount$ = this.store.pipe(select(selectors.selectRequestTypeCount));

  allowedRequestTypes$ = this.store.pipe(
    select(selectors.selectAllowedRequestTypes)
  );

  hasAllowedRequestTypes$ = this.store.pipe(
    select(selectors.selectHasAllowedRequestTypes)
  );

  isRequired$ = this.store.pipe(select(selectors.selectIsRequired));

  requiredRequestTypes$ = this.store.pipe(
    select(selectors.selectRequiredRequestTypes)
  );

  isValid$ = this.store.pipe(select(selectors.selectIsValid));

  constructor(private store: Store) {}

  getAll(registrationId: string) {
    this.store.dispatch(
      new actions.GetAllInfoRequestsRequestAction({ registrationId })
    );
  }

  create(data: ApplicationsApi.CreateRequestData) {
    this.store.dispatch(new actions.CreateRequestRequestAction({ data }));
  }

  openResendConfirmDialog(infoRequest: ApplicationsApi.UserInfoRequest) {
    this.store.dispatch(new actions.OpenResendDialogAction({ infoRequest }));
  }

  delete(infoRequestId: string) {
    this.store.dispatch(new actions.DeleteRequestRequestAction(infoRequestId));
  }
}
