import { App451Api } from '@element451-libs/models451';
import {
  normalizeComponents,
  PageRowType
} from '@element451-libs/page451-rows';
import { get } from '@element451-libs/utils451/get';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isBoolean } from 'lodash';
import { selectApp } from '../app.feature';
import { SITE_ACTIONS, SiteAction } from './site.actions';

export interface SiteState {
  loaded: boolean;
  site: App451Api.App451;
}

export const initialState: SiteState = {
  loaded: false,
  site: {
    guid: null,
    type: null,
    name: null,
    active: null,
    status: null,
    applications: [],
    components: [],
    configuration: {
      title: null,
      description: null,
      analytics: null,
      primary_domain: null,
      website_url: null,
      hosts: [],
      messenger: null
    },
    layout: {
      preview_key: null,
      preview_image: null,
      general: null,
      header: null,
      footer: null
    },
    help: {
      text: null
    },
    api_tokens: [],
    client: {},
    hosts: [],
    privacy_policy: null,
    has_unpublished_updates: null,
    last_publication_at: null,
    updated_at: null,
    created_at: null,
    student_authentication: []
  }
};

export function siteReducer(
  state: SiteState = initialState,
  action: SiteAction
): SiteState {
  switch (action.type) {
    case SITE_ACTIONS.LOAD_SITE_SETTINGS:
      return {
        loaded: true,
        site: action.payload
      };

    default:
      return state;
  }
}

export const siteFeature = 'site';

const _selectSiteFeature = createFeatureSelector<SiteState>(siteFeature);

const selectSiteState = createSelector(selectApp, _selectSiteFeature);

export const selectLoaded = createSelector(
  selectSiteState,
  state => state.loaded
);

export const selectSite = createSelector(selectSiteState, state => state.site);

export const selectAllApplications = createSelector(
  selectSite,
  site => site.applications
);

const _selectComponents = createSelector(selectSite, site =>
  normalizeComponents(site.components)
);

export const selectLayout = createSelector(selectSite, site =>
  get(site, 'layout')
);

export const selectConfiguration = createSelector(selectSite, site =>
  get(site, 'configuration')
);

export const selectTimezone = createSelector(selectSite, site =>
  get(site, 'client', 'timezone')
);

export const selectColors = createSelector(selectLayout, site =>
  get(site, 'general', 'colors')
);

export const selectHeader = createSelector(selectLayout, site =>
  get(site, 'header')
);

export const selectFooter = createSelector(selectLayout, site =>
  get(site, 'footer', 'text')
);

export const selectGoogleTagManagerId = createSelector(
  selectConfiguration,
  configuration => get(configuration, 'analytics', 'google_tag_id')
);

export const selectTitle = createSelector(selectConfiguration, configuration =>
  get(configuration, 'title')
);

export const selectDescription = createSelector(
  selectConfiguration,
  configuration => get(configuration, 'description')
);

export const selectSocialMediaLinks = createSelector(
  selectConfiguration,
  configuration => get(configuration, 'social_media_links')
);

export const selectIsEditProfileEnabled = createSelector(
  selectConfiguration,
  configuration => {
    const editProfile = get(configuration, 'edit_profile');
    if (isBoolean(editProfile)) {
      return editProfile;
    }
    return true;
  }
);

export const selectPrivacyPolicy = createSelector(
  selectSite,
  site => site.privacy_policy
);

export const selectHelpText = createSelector(selectSite, site =>
  get(site, 'help', 'text')
);

export const selectMessengerSetup = createSelector(
  selectConfiguration,
  configuration => get(configuration, 'messenger')
);

export const selectIsMessengerActive = createSelector(
  selectMessengerSetup,
  setup => get(setup, 'active')
);

export const selectMessengerColorType = createSelector(
  selectMessengerSetup,
  setup => get(setup, 'appearance', 'launcher_icon_color')
);

export const selectMessengerColor = createSelector(
  selectMessengerColorType,
  selectColors,
  (colorType, colors) => colors && colors[colorType]
);

export const selectLogoUrl = createSelector(selectSite, site => {
  const schoolLogo = get(site, 'layout', 'general', 'school_logo');
  return schoolLogo && schoolLogo.length ? schoolLogo[0].url : null;
});

export const selectFaviconIco = createSelector(selectSite, site => {
  const schoolFaviconIco = get(site, 'layout', 'general', 'school_favicon_ico');
  return schoolFaviconIco && schoolFaviconIco.length
    ? schoolFaviconIco[0].url
    : null;
});

export const selectFaviconPng = createSelector(selectSite, site => {
  const schoolFaviconPng = get(site, 'layout', 'general', 'school_favicon_png');
  return schoolFaviconPng && schoolFaviconPng.length
    ? schoolFaviconPng[0].url
    : null;
});

export const selectAuthenticationTypes = createSelector(selectSite, site =>
  get(site, 'student_authentication')
);

export const selectHasEnabledAuthenticationTypes = createSelector(
  selectAuthenticationTypes,
  types => types.filter(type => type.enabled).length > 0
);

export const selectSaml2IdpKey = createSelector(selectSite, site =>
  get(site, 'saml2_idp_key')
);

export const selectComponents = createSelector(
  _selectComponents,
  selectHasEnabledAuthenticationTypes,
  (components, hasEnabledAuthTypes) => {
    if (hasEnabledAuthTypes) {
      return components;
    }

    console.warn(`No authentication types enabled, disabling login...`);

    return components.map(component => {
      if (component.type === PageRowType.BillboardAppType) {
        return {
          ...component,
          elements: {
            ...component.elements,
            buttonSignIn: false,
            buttonStart: false
          }
        };
      }
      return component;
    });
  }
);

export const selectBaseSettings = createSelector(
  selectTitle,
  selectDescription,
  selectColors,
  selectGoogleTagManagerId,
  selectFaviconIco,
  selectFaviconPng,
  (title, description, colors, googleTagManagerId, faviconIco, faviconPng) => ({
    title,
    description,
    colors: colors || {
      primary: null,
      secondary: null,
      link: null
    },
    googleTagManagerId,
    faviconIco,
    faviconPng
  })
);
