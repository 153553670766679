import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CeebApi } from '@element451-libs/models451';

import { API451_URL_FACTORY, UrlFactory } from '../api-client';
import { ElmResponse } from '../shared';

import { CeebApiModule } from './ceeb-api.module';

// shorthand
type R<T> = ElmResponse<T>;

@Injectable({
  providedIn: CeebApiModule
})
export class CeebApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory
  ) {}

  search(text: string, type: string) {
    const params = new HttpParams()
      .set('type', type || 'null')
      .set('search', text);

    return this.http.get<R<CeebApi.CeebSchool[]>>(this.url(`data/schools`), {
      params
    });
  }
}
