import { ApplicationsApi } from '@element451-libs/models451';
import { replaceAll } from '@element451-libs/utils451/helpers';
import { produce } from 'immer';
import { Token } from '../tokens/tokens.models';

export function replaceHeroTokens(hero: ApplicationsApi.Hero, tokens: Token[]) {
  return produce(hero, draft => {
    for (const { token, value } of tokens) {
      const replacement = token.startsWith('[link:')
        ? generateLinkReplacement(token, value)
        : value;

      // Replace token in hero fields
      const replaceInField = (field?: string) =>
        field && replaceAll(field, token, replacement);

      draft.title = replaceInField(draft.title);
      draft.description = replaceInField(draft.description);

      const congratulations = (draft as ApplicationsApi.PositiveOutcomeHero)
        .congratulations;
      if (congratulations) {
        congratulations.button_label = replaceInField(
          congratulations.button_label
        );
        congratulations.message = replaceInField(congratulations.message);
        congratulations.subtitle = replaceInField(congratulations.subtitle);
        congratulations.title = replaceInField(congratulations.title);
      }
    }
  });
}

export function generateLinkReplacement(token: string, value?: string) {
  const urlMatch = token.match(/url=([^,\]]+)/);
  const url = urlMatch ? urlMatch[1] : '';
  return `<a href="${url}" target="_blank">${value || url}</a>`;
}
