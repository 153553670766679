import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';

import { DynamicFieldModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Component({
  selector: 'lum-df-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent extends FieldConfigDirective<DynamicFieldModel> {
  /**
   * For partial updates, we actually unset the value if the checkbox is not checked.
   * This is a workaround to avoid sending the value "false" when the checkbox is not checked.
   * This helps with required validations on backend, as it just checks if the field is present or not.
   */
  onChange() {
    if (this.fieldControl.value) {
      this.onBlur();
    } else {
      this.onClear();
    }
  }
}
