import { isNil, size } from 'lodash';
import { IField, IFieldOption } from '../api-data';
import { DynamicFieldModel } from '../models';

export function hasDefault(field: IField | DynamicFieldModel) {
  return !isNil(field.default) && field.default !== '';
}

export function getFieldOptions(
  field: Pick<
    IField,
    'data_source' | 'data_source_settings' | 'options' | 'default'
  >
): IFieldOption[] {
  if (!field) return [];
  let options: IFieldOption[] = [];

  if (size(field.data_source_settings) > 0) {
    options = field.data_source_settings;
  }
  if (size(field.options) > 0) {
    options = field.options;
  }

  if (hasDefault(field)) {
    return options.map(o =>
      o.value === field.default
        ? { ...o, checked: true }
        : { ...o, checked: false }
    );
  } else {
    // ensure no options are checked when there's no default value
    return options.map(o => ({ ...o, checked: false }));
  }
}
