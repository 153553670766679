import { IConditional } from './conditional';
import { IFile } from './file';
import { IFilter } from './filter';
import { IValidation, ValidationType } from './validation';

// types of controls
export type ApiFieldType =
  | 'select'
  | 'radio'
  | 'boolean'
  | 'checkbox'
  | 'text'
  | 'datepicker'
  | 'name'
  | 'email'
  | 'number'
  | 'markdown'
  | 'password'
  | 'textarea'
  | 'file'
  | 'multi_file'
  | 'phone'
  | 'family-member'
  | 'ceeb'
  | 'address'
  | 'fieldset'
  | 'datetime'
  | 'audio_video';

export type ConditionalOperator = '$and' | '$or';

export enum FieldSource {
  Locked = 'locked'
}

export enum SchoolWidgetType {
  NoSearch = 'ceeb_no_search'
}

export interface IField {
  label?: string;
  slug?: string; // used for sending to the server
  name?: string; // if there is no slug property then use this for sending to the server

  // type of the form control
  type?: string; // select | radio | text | datepicker | name* | checkbox

  // accessories
  new_line?: boolean;
  add_to_preview?: boolean;
  hidden?: boolean;
  multiple?: boolean;
  help_text?: string;
  index_weight?: number;
  size?: string;
  input_widget?:
    | 'toggle'
    | 'default'
    | 'force_select'
    | 'file_sync'
    | 'honey_pot'
    | 'select_multiple'
    | SchoolWidgetType.NoSearch;
  autocomplete?: boolean;
  disabled?: boolean;

  conditionals_operator?: ConditionalOperator;

  // data
  options?: IFieldOption[];
  // if data is got from the db
  data_source?: string | boolean;
  data_source_text?: string;
  data_source_value?: string;
  data_source_settings?: any[]; // data

  // functionality
  validations?: IValidation[];
  conditionals?: IConditional[];

  filters_operator?: ConditionalOperator;
  filters?: IFilter;
  target_filters?: IFilter;

  data_type?: DataType;

  // if markdown field
  value?: string;

  // if grouped field
  subfields?: IField[];

  /**
   * file upload field has this
   */
  document_type_guid?: string;

  show?: string; // datepicker

  source?: FieldSource;
  notes?: string;
  placeholder?: string;
  default?: string;

  // if email field
  email_confirmation?: boolean;

  // needed in Field Management for Custom Fields
  transformations?: Transformation[];

  // extra infromation that you may require to store next with the field
  context?: {
    parent_name: string;
    parent_slug: string;
    parent_type: string;
  };
}

export interface IFieldOption {
  text?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
}

export interface IFieldWithData<V = any, M = any> {
  /**
   * DEPRECATED
   *
   * use value instead
   */
  files?: IFile[];

  // display
  displayValue?: string;
  label?: string;

  // this will be used for passing
  // extra options that need to be handled
  metadata?: M; // files

  slug?: string;

  // key or name are needed for uniquness
  name?: string;
  key?: string;

  type?: string;

  // tree structure
  subfields?: IFieldWithData[];

  value?: V;

  weight?: number;
  hidden?: boolean;
}

export type TransformationType = 'lowercase' | 'hash';

export interface Transformation {
  type: TransformationType;
}

export type DataType =
  | 'string'
  | 'boolean'
  | 'object'
  | 'array'
  | 'date'
  | 'collection'
  | 'collection-item';

export function convertAsyncUploadsToSyncUploads(field: IField): void {
  if (field.type && isFileFieldType(field.type)) {
    field.input_widget = 'file_sync';
  }
}

export function isMultiFileType(type: string): boolean {
  return type === 'multi_file';
}

export function isFileFieldType(type: string): boolean {
  return type === 'file' || type === 'multi_file' || type === 'audio_video';
}

export function applyEmailVerification(field: IField): void {
  if (field.type === 'email') {
    field.validations ||= [];
    field.validations = [
      ...field.validations,
      {
        type: ValidationType.EmailVerify
      }
    ];
  }
}

export function applyPhoneVerification(field: IField): void {
  if (field.type === 'phone') {
    field.validations = [{ type: ValidationType.PhoneVerify }];
  }
}

export const DEFAULT_ICON = 'font-color';

export const GROUP_ICON = 'list-bullets-2';

const ICONS: Partial<{
  [key in ApiFieldType]: string;
}> = {
  text: DEFAULT_ICON,
  audio_video: 'play-button',
  email: 'email-2',
  select: 'view-headline',
  radio: 'record-button',
  textarea: 'align-justify',
  checkbox: 'check-box-1',
  boolean: 'check-circle-1',
  markdown: 'text',
  datepicker: 'calendar-2',
  datetime: 'calendar-2',
  file: 'upload-2',
  multi_file: 'upload-box-5'
};

const LABELS: Partial<{
  [key in ApiFieldType]: string;
}> = {
  text: 'Text',
  email: 'Email',
  audio_video: 'Audio/Video',
  select: 'Select',
  radio: 'Radio',
  textarea: 'Textarea',
  checkbox: 'Checkbox',
  boolean: 'Yes/No',
  markdown: 'Markdown',
  datepicker: 'Date',
  datetime: 'Datetime',
  file: 'File',
  multi_file: 'Multi File'
};

export function getIconByType(type: ApiFieldType | string): string {
  return ICONS[type as ApiFieldType] || DEFAULT_ICON;
}

export function getLabelByType(type: ApiFieldType | string): string {
  return LABELS[type as ApiFieldType] || '';
}
