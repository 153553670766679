declare const FroalaEditor: any;

export const elmFontsPlugin = 'elmFonts';

export const elmFontFamily = 'elmFontFamily';
export const elmFontVariants = 'elmFontVariants';

export function initElmFontsPlugin() {
  const global = {
    availableFonts: null as any,
    listedFonts: {} as any,
    loadedFonts: {} as any
  };

  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
    elmFonts: {}
  });

  FroalaEditor.PLUGINS[elmFontsPlugin] = function (editor: any) {
    const $ = editor.$;

    let _$search: any;
    let _$searchClear: any;
    let _$dropdown: any;

    let _selectedFamily: any;
    let _selectedVariant: any;

    let _debounce: any;

    const _onClick = function (e: any) {
      e.stopPropagation();
    };

    const _onClear = function (e: any) {
      _clearSearch();
      _$searchClear.hide();
      search();
    };

    const _onMouseDown = function (e: any) {
      e.stopPropagation();
    };

    const _onKeyDown = function (e: any) {
      e.stopPropagation();
    };

    const _onKeyUp = function (e: any) {
      e.target.value.length ? _$searchClear.show() : _$searchClear.hide();
      search(e.target.value);
    };

    function _init() {
      if (!Object.keys(global.listedFonts).length) {
        if (editor.opts.elmFonts && editor.opts.elmFonts['defaultFonts']) {
          editor.opts.elmFonts.defaultFonts.forEach(function (font: any) {
            if (font) global.listedFonts[font.family] = font;
          });
        }

        const customFonts = editor.opts.elmFonts['customFonts'];
        if (customFonts) {
          const customFontSet = new Set(
            customFonts.map((font: any) => font.family)
          );

          // load custom fonts from google fonts
          _loadFonts().then(({ items }) => {
            const fonts = items.filter((font: any) =>
              customFontSet.has(font.family)
            );
            fonts.forEach((font: any) => {
              global.listedFonts[font.family] = font;
            });
          });
        }

        if (editor.opts.elmFonts && editor.opts.elmFonts['preloadFonts']) {
          const fontFiles: any = [];
          editor.opts.elmFonts.preloadFonts.forEach(function (font: any) {
            if (font) {
              global.listedFonts[font.family] = font;
              font.variants.forEach(function (variant: any) {
                fontFiles.push(font.family + ':' + variant);
              });
            }
          });
          _loadFontCSS(fontFiles);
        }

        _sortFontList();
      }
    }

    function applyFamily(familyName: any) {
      _addSelectedFamily(familyName);

      if (!editor.selection.isCollapsed()) {
        _selectedFamily = familyName;
        editor.selection.restore();
        editor.format.applyStyle(
          'font-family',
          _getExpandedFamilyName(familyName)
        );

        // Update style and weight if none is set or current one doesn't exist in this family
        const computedStyle = _getComputedStyle();

        let weight = computedStyle.weight;
        let style = computedStyle.style;

        const variants = (global.listedFonts as any)[familyName]
          ? (global.listedFonts as any)[familyName].variants
          : null;

        const currentVariant = _weightStyleToVariant(weight, style);

        // If current variant doesn't exits, pick one from existing
        if (variants && variants.indexOf(currentVariant) === -1) {
          const newVariant =
            style === 'italic' && variants.indexOf('italic') > -1
              ? 'italic'
              : 'regular';

          const fontVariant: any = _parseVariant(newVariant);

          weight = fontVariant.weight;
          style = fontVariant.style;
        }

        editor.format.applyStyle('font-weight', weight);
        editor.format.applyStyle('font-style', style);
      }
    }

    function refreshFamily($btn: any) {
      editor.selection.restore();

      _selectedFamily = _getComputedStyle().fontFamily;
      let familyLabel;

      if ((global.listedFonts as any)[_selectedFamily])
        familyLabel = global.listedFonts[_selectedFamily].family;

      $btn
        .find('> span')
        .text(familyLabel || editor.opts.elmFonts.fontFamilyDefaultSelection);

      _unsubscribeFamily();
    }

    function refreshOnShowFamily($btn: any, $dropdown: any) {
      editor.selection.save();
      _$dropdown = $dropdown;

      let $searchBox;

      if (!$('.elm-fonts-search', $dropdown).length) {
        $searchBox = $('<div class="elm-fonts-search-box">').html(
          '<input class="elm-fonts-search" type="text" placeholder="Search Google Fonts">' +
            '<button class="elm-fonts-clear fa fa-times-circle"></button>'
        );
        $('.fr-dropdown-wrapper', $dropdown).prepend($searchBox);
      }

      _$search = $('.elm-fonts-search', $searchBox || $dropdown);
      _$searchClear = $('.elm-fonts-clear', $searchBox || $dropdown).hide();

      _subscribeFamily();
      _clearSearch();
      _listFamily();
    }

    function _subscribeFamily() {
      _$searchClear.on('click', _onClear);
      _$search.on('click', _onClick);
      _$search.on('mousedown', _onMouseDown);
      _$search.on('keydown', _onKeyDown);
      _$search.on('keyup', _onKeyUp);
    }

    function _unsubscribeFamily() {
      if (_$search) {
        _$searchClear.off('click', _onClear);
        _$search.off('click', _onClick);
        _$search.off('mousedown', _onMouseDown);
        _$search.off('keydown', _onKeyDown);
        _$search.off('keyup', _onKeyUp);
      }
    }

    // Variants
    function applyVariants(val: any) {
      if (!editor.selection.isCollapsed()) {
        _selectedVariant = _parseVariant(val);
        editor.selection.restore();

        editor.format.applyStyle('font-weight', _selectedVariant.weight);
        editor.format.applyStyle('font-style', _selectedVariant.style);
      }
    }

    function refreshVariants($btn: any) {
      editor.selection.restore();

      const font = _getComputedStyle();
      const variant = _weightStyleToVariant(font.weight, font.style);

      _selectedVariant = _parseVariant(variant);

      const label = _selectedVariant
        ? _selectedVariant.label
        : editor.opts.elmFonts.fontVariantsDefaultSelection;

      $btn.find('> span').text(label);
    }

    function refreshOnShowVariants($btn: any, $dropdown: any) {
      editor.selection.save();
      _$dropdown = $dropdown;

      let variants = null;

      if (global.listedFonts[_selectedFamily]) {
        const font = global.listedFonts[_selectedFamily];
        variants = font.variants;

        const fontsToLoad: any = [];
        variants.forEach(function (variant: any) {
          fontsToLoad.push(font.family + ':' + variant);
        });

        _loadFontCSS(fontsToLoad);
      }

      _listVariants(variants);
    }

    function _addSelectedFamily(familyName: any) {
      if (global.listedFonts[familyName]) return;

      const selectedFamily = global.availableFonts.filter(function (font: any) {
        return font.family === familyName;
      });

      global.listedFonts[familyName] = {
        family: familyName,
        category: selectedFamily[0].category,
        variants: selectedFamily.length
          ? selectedFamily[0].variants
          : ['regular']
      };

      _sortFontList();
    }

    function _sortFontList() {
      const sortedList: any = {};
      const keysSorted = Object.keys(global.listedFonts).sort();

      keysSorted.forEach(function (key) {
        sortedList[key] = global.listedFonts[key];
      });

      global.listedFonts = sortedList;
    }

    function _loadFonts() {
      const { apiUrl, apiKey, sorting } = editor.opts.elmFonts;

      return window
        .fetch(`${apiUrl}?key=${apiKey}&sort=${sorting}`)
        .then(res => res.json());
    }

    function _loadFontCSS(fontFiles: any) {
      const $head = $('head');

      const fontSet = fontFiles.reduce(function (set: any, font: any) {
        // Filter out already downloaded fonts
        if (global.loadedFonts[font]) return set;

        const fontInfo = font.split(':');

        const family = fontInfo[0];
        const variant = fontInfo[1];

        if (set[family]) {
          set[family].push(variant);
        } else {
          set[family] = [variant];
        }

        return set;
      }, {});

      Object.keys(fontSet).forEach(function (family) {
        const variants = fontSet[family];

        $head.append(
          $('<link>').attr({
            rel: 'stylesheet',
            href:
              editor.opts.elmFonts.apiCSSUrl +
              family.replace(/ /g, '+') +
              ':' +
              variants.join(',') +
              '&subset=latin'
          })
        );

        // Mark fonts as loaded
        variants.forEach(function (variant: any) {
          global.loadedFonts[family + ':' + variant] = true;
        });
      });
    }

    function search(term?: any) {
      if (!global.availableFonts) {
        global.availableFonts = [];
        _loadFonts().then(function (data) {
          global.availableFonts = data.items;
          search(term);
        });
      } else if (term && term.length > 1) {
        clearTimeout(_debounce);
        _debounce = setTimeout(function () {
          doSearch(term);
        }, 200);
      } else {
        _listFamily();
      }
    }

    function doSearch(term: any) {
      const fontsToLoad: any = [];
      const found = global.availableFonts
        .filter(function (font: any) {
          return font.family.toLowerCase().indexOf(term.toLowerCase()) !== -1;
        })
        .reduce(function (_fonts: any, font: any) {
          fontsToLoad.push(font.family + ':regular');

          _fonts[font.family] = {
            family: font.family,
            category: font.category
          };

          return _fonts;
        }, {});

      _loadFontCSS(fontsToLoad);
      _listFamily(found);
    }

    function _clearSearch() {
      if (_$search) {
        _$search.val(null);
      }
    }

    function _getComputedStyle() {
      const element = editor.selection.element();
      const computed = editor.win.getComputedStyle(element);

      const fontFamily = element.style.fontFamily || computed.fontFamily;

      return {
        fontFamily: fontFamily.split(',')[0].replace(/["|']/g, ''),
        weight: element.style.fontWeight || computed.fontWeight,
        style: element.style.fontStyle || computed.fontStyle
      };
    }

    function _listFamily(items?: any) {
      const list = _renderFamilyList(items || global.listedFonts);

      $('.fr-dropdown-list', _$dropdown).html(list);
    }

    function _getExpandedFamilyName(family: any) {
      let expandedName =
        family.indexOf(' ') !== -1 ? "'" + family + "'" : family;

      if (global.listedFonts[family]) {
        expandedName += ',' + global.listedFonts[family].category;
      }

      return expandedName;
    }

    function _renderFamilyList(items: any) {
      let list = '';

      for (const key of Object.keys(items)) {
        list +=
          '<li><a class="fr-command ' +
          (_selectedFamily && key.indexOf(_selectedFamily) >= 0
            ? 'fr-active'
            : '') +
          '" data-cmd="elmFontFamily" data-param1="' +
          key +
          '" title="' +
          items[key].family +
          '" style="font-family: ' +
          _getExpandedFamilyName(key) +
          ';">' +
          items[key].family +
          '</a></li>';
      }

      return list;
    }

    function _listVariants(items: any) {
      const options =
        items || Object.keys(editor.opts.elmFonts.defaultVariants);

      const list = _renderVariantsList(options);

      $('.fr-dropdown-list', _$dropdown).html(list);
    }

    function _renderVariantsList(items: any) {
      let list = '';

      items.forEach(function (item: any) {
        const variant: any = _parseVariant(item);

        const fontStyle = [
          'font-weight: ' + variant.weight + ';',
          'font-style: ' + variant.style + ';'
        ];

        if (_selectedFamily) {
          fontStyle.unshift('font-family: ' + _selectedFamily + ';');
        }

        list +=
          '<li><a class="fr-command ' +
          (_selectedVariant && _selectedVariant.key === variant.key
            ? 'fr-active'
            : '') +
          '" data-cmd="elmFontVariants" data-param1="' +
          item +
          '" title="' +
          variant.label +
          '" style="' +
          fontStyle.join(' ') +
          '">' +
          variant.label +
          '</a></li>';
      });

      return list;
    }

    function _weightStyleToVariant(weight: any, style: any) {
      if (weight === 'normal' && style === 'normal') {
        return 'regular';
      } else if (weight === 'normal' && style === 'italic') {
        return 'italic';
      }

      return (
        (weight === 'normal' ? 'regular' : weight) +
        (style !== 'normal' ? style : '')
      );
    }

    function _parseVariant(item: any) {
      if (!item) return null;
      item += '';

      const weightLookup: any = {
        '100': 'Thin',
        '200': 'Extra-Light',
        '300': 'Light',
        '400': 'Regular',
        regular: 'Regular',
        italic: 'Italic',
        '500': 'Medium',
        '600': 'Semi-bold',
        '700': 'Bold',
        bold: 'Bold',
        '800': 'Extra-bold',
        '900': 'Black'
      };

      for (const weight of Object.keys(weightLookup)) {
        const weightIndex = item.search(weight);
        const styleIndex = item.search('italic');

        if (weightIndex === 0) {
          return {
            key: item,
            weight:
              weight === 'italic' || weight === 'regular' ? 'normal' : weight,
            style: styleIndex >= 0 ? 'italic' : 'normal',
            label:
              (weight === 'regular' && styleIndex > 0
                ? ''
                : weightLookup[weight]) + (styleIndex > 0 ? ' Italic' : '')
          };
        }
      }
    }

    return {
      _init: _init,
      applyFamily: applyFamily,
      refreshFamily: refreshFamily,
      refreshOnShowFamily: refreshOnShowFamily,
      search: search,
      doSearch: doSearch,
      applyVariants: applyVariants,
      refreshVariants: refreshVariants,
      refreshOnShowVariants: refreshOnShowVariants
    };
  };

  FroalaEditor.DefineIcon(elmFontFamily, { NAME: 'font' });
  FroalaEditor.RegisterCommand(elmFontFamily, {
    title: 'Font Famiy',
    type: 'dropdown',
    undo: true,
    focus: true,
    plugin: elmFontsPlugin,
    displaySelection: function (editor: any) {
      return editor.opts.elmFonts.fontFamilySelection;
    },
    defaultSelection: function (editor: any) {
      return editor.opts.elmFonts.fontFamilyDefaultSelection;
    },
    displaySelectionWidth: 94,
    refreshAfterCallback: false,
    refresh: function ($btn: any) {
      this.elmFonts.refreshFamily($btn);
    },
    refreshOnShow: function ($btn: any, $dropdown: any) {
      this.elmFonts.refreshOnShowFamily($btn, $dropdown);
    },
    html: function () {
      return '<ul class="fr-dropdown-list"></ul>';
    },
    callback: function (cmd: any, val: any) {
      this.elmFonts.applyFamily(val);
    }
  });

  FroalaEditor.DefineIcon(elmFontVariants, { NAME: 'bold' });
  FroalaEditor.RegisterCommand(elmFontVariants, {
    title: 'Variants',
    type: 'dropdown',
    focus: true,
    undo: true,
    plugin: elmFontsPlugin,
    displaySelection: function (editor: any) {
      return editor.opts.elmFonts.fontVariantsSelection;
    },
    defaultSelection: function (editor: any) {
      return editor.opts.elmFonts.fontVariantsDefaultSelection;
    },
    displaySelectionWidth: 94,
    refreshAfterCallback: false,
    refresh: function ($btn: any) {
      this.elmFonts.refreshVariants($btn);
    },
    refreshOnShow: function ($btn: any, $dropdown: any) {
      this.elmFonts.refreshOnShowVariants($btn, $dropdown);
    },
    html: function () {
      return '<ul class="fr-dropdown-list"></ul>';
    },
    callback: function (cmd: any, val: any) {
      this.elmFonts.applyVariants(val);
    }
  });
}
