import { ApplicationsApi, FormsApi, UserApi } from '@element451-libs/api451';
import { CacheableAction } from '@element451-libs/utils451/rxjs';
import { Action } from '@ngrx/store';

export const enum EDIT_PROFILE_ACTIONS {
  GET_PROFILE_REQUEST = '[Edit Profile] Request Get Profile',
  GET_PROFILE_SUCCESS = '[Edit Profile] Success Get Profile',
  GET_PROFILE_FAIL = '[Edit Profile] Fail Get Profile',

  UPDATE_PROFILE_REQUEST = '[Edit Profile] Request Update Profile',
  UPDATE_PROFILE_SUCCESS = '[Edit Profile] Success Update Profile',
  UPDATE_PROFILE_FAIL = '[Edit Profile] Fail Update Profile',

  GET_FORMS_REQUEST = '[Edit Profile] Request Get Info Request',
  GET_FORMS_SUCCESS = '[Edit Profile] Success Get Info Request',
  GET_FORMS_FAIL = '[Edit Profile] Fail Get Info Request'
}

export interface UpdateProfileSuccessResponse {
  data: ApplicationsApi.FormEntry[];
  fields: FormsApi.Field[];
}

/**
 * GET PROFILE
 */

export class GetProfileRequestAction extends CacheableAction {
  readonly type = EDIT_PROFILE_ACTIONS.GET_PROFILE_REQUEST;
}

export class GetProfileSuccessAction implements Action {
  readonly type = EDIT_PROFILE_ACTIONS.GET_PROFILE_SUCCESS;

  constructor(public payload: any) {}
}

export class GetProfileFailAction implements Action {
  readonly type = EDIT_PROFILE_ACTIONS.GET_PROFILE_FAIL;

  constructor(public payload: any) {}
}

/**
 * UPDATE PROFILE
 */

export class UpdateProfileRequestAction implements Action {
  readonly type = EDIT_PROFILE_ACTIONS.UPDATE_PROFILE_REQUEST;

  constructor(public payload: UserApi.BasicInformationItem) {}
}

export class UpdateProfileSuccessAction implements Action {
  readonly type = EDIT_PROFILE_ACTIONS.UPDATE_PROFILE_SUCCESS;

  constructor(public payload: UpdateProfileSuccessResponse) {}
}

export class UpdateProfileFailAction implements Action {
  readonly type = EDIT_PROFILE_ACTIONS.UPDATE_PROFILE_FAIL;

  constructor(public payload: any) {}
}

/**
 * GET FORMS
 */

export class GetFormsRequestAction extends CacheableAction {
  readonly type = EDIT_PROFILE_ACTIONS.GET_FORMS_REQUEST;
}

export class GetFormsSuccessAction implements Action {
  readonly type = EDIT_PROFILE_ACTIONS.GET_FORMS_SUCCESS;

  constructor(public payload: UserApi.BasicInformationForm) {}
}

export class GetFormsFailAction implements Action {
  readonly type = EDIT_PROFILE_ACTIONS.GET_FORMS_FAIL;

  constructor(public payload: any) {}
}

export type EditProfileAction =
  | GetProfileRequestAction
  | GetProfileSuccessAction
  | GetProfileFailAction
  | UpdateProfileRequestAction
  | UpdateProfileSuccessAction
  | UpdateProfileFailAction
  | GetFormsRequestAction
  | GetFormsSuccessAction
  | GetFormsFailAction;
