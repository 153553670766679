import { AuthApi } from '../auth';
import { ConditionsApi } from '../conditions';
import { DepositsApi } from '../deposits';
import { ApiFile, FilesApi } from '../files';
import { FormsApi } from '../forms';
import {
  ConditionalOperator,
  FormValue,
  ICondition,
  IFieldOption,
  IFile,
  IFormData
} from '../forms451/public_api';
import { PaymentApi } from '../payment-providers';
import { TokensApi } from '../tokens';

export interface SubmitData extends FormData {
  prevention_conditions?: ConditionsApi.ApplicationSubmissionPreventionRule[];
}

export interface SubmitDataDto {
  _form: string;
  prevention_conditions?: string[];
}

export interface Application {
  _groups?: string;
  _programs?: string;
  _progress?: string;
  _terms?: string;
  active?: boolean;
  guid?: string;
  name: string;
  snap_app: boolean;
  default_view_app?: ApplicationViewType;
  completed_alert: boolean;
  show_application_status?: boolean;
  submitted_status_text?: string;
  show_documents_section?: boolean;
  deposit?: {
    _id: string | null;
  };
  decision_confirmation: {
    active: boolean;
  };
  icon: string;
  description?: string;
  index_weight: number;
  created_at: string;
  updated_at: string;
  info_request_title?: string;
  info_request_description?: string;
  info_request_description_optional?: string;
  sections?: Section[];
  _programs_data?: ProgramsData[];
  _terms_data?: TermsData[];
  _groups_data?: GroupsData[];
  _progress_data?: ProgressData;
  _registration_data?: FormData;
  submit: SubmitData;
  _user_application_data?: UserApplicationData;
  documents_section?: DocumentsSection;
  payment?: PaymentApi.PaymentConfig;
  user_applications?: UserApplications[];
  _uploaded_files?: any;
  info_request_items: InfoRequestItem[];
  supplemental_forms?: SupplementalFormsStep[];
  _user_applications?: {
    registered_at: string;
    updated_at: string;
    last_login: string;
    registration_id: string;
    numeric_progress: number;
  }[];
  request_info_sidebar_content: SidebarContent;
  _sidebar_content: SidebarContent;
  _dashboard_content: DashboardContent;
  preview_settings?: {
    hide_blanks: boolean;
  };
  international_students?: boolean;
}

export interface ApplicationDto extends Omit<Application, 'submit'> {
  submit: SubmitDataDto;
}

export enum ApplicationViewType {
  full = 'full',
  snap = 'snap'
}

export interface InfoRequestConditional {
  criteria: InfoRequestCriteria;
  actions: {
    info_request_min: number;
    info_request_max: number;
  };
}

export interface InfoRequestCriteria {
  conditions: InfoRequestCondition[];
  operator: string;
}

export interface InfoRequestCondition {
  criteria: {
    conditions: ICondition[];
    operator: string;
  };
}

export interface InfoRequestItem {
  info_request: string;
  info_request_name: string;
  info_request_max: number;
  info_request_min: number;
  info_request_filters: InfoRequestConditional[];
  index_weight: number;
}

export interface InfoRequestDetails {
  info_request_description: string;
  info_request_description_optional: string;
  info_request_title: string;
}

export interface ProgressData {
  progress_guid: string;
  numeric_progress: number;
  status: string;
  count: any;
}

export interface TermsData {
  guid: string;
  name: string;
  code: string;
  active: boolean;
  subdom: string;
  valid_for?: string;
  title?: string;
  hobson?: string;
  abbrev_letters?: string;
  abbrev_number?: number;
}

export interface ProgramsData {
  guid: string;
  name: string;
  active: boolean;
  subdom: string;
  hobson?: string;
  code?: string;
  choice_order?: string;
  choice_value?: string;
}

export interface GroupsData {
  _id: string;
  name: string;
}

export interface FormData {
  form_data: IFormData;
  user_data: any;
}

export interface AppPayment extends PaymentApi.PaymentConfigExpanded {
  completed: boolean;
  required_to_submit: boolean;
  labels: PaymentApi.PaymentConfigExpanded['labels'] & {
    payment_dialog_title?: string;
  };
  item_code?: string;
}

export interface DepositPayment extends DepositsApi.Deposit {
  item_code?: string;
  payment?: {
    completed: boolean;
    time: string;
  };
}

export type Payment = AppPayment | DepositPayment;

export interface DocumentsSection {
  student_forms: any[];
  recommender_forms: any[];
  request_types: string[];
  user_info_requests: any[];
}

export interface UserApplications {
  guid: string;
  submission: UserApplicationsSubmission;
  payment: UserApplicationsPayment;
}

export interface UserApplicationsSubmission {
  school_problems: boolean;
  convicted: boolean;
  legal_note_confirm: boolean;
  electronic_sign: string;
  submitted: boolean;
  submitted_time: boolean;
}

export interface UserApplicationsPayment {
  app_guid: string;
  amount: string;
  currency: string;
  action: string;
  successful: boolean;
}

export interface TextValueChecked {
  text: string;
  value: any;
  checked: boolean;
}

export interface KeyValue<T = any> {
  key: string;
  value: T;
}

export interface MultiFileUserData {
  files: IFile[] | null;
}

export type UserDataValue =
  | string
  | number
  | boolean
  | TextValueChecked[]
  | IFieldOption[]
  | IFile
  | MultiFileUserData
  | null
  | KeyValue[]
  | { [key: string]: UserDataValue }[];

export interface UserApplicationData {
  [key: string]: UserDataValue | UserApplicationData;
}

export interface Step {
  _id: string;
  name: string;
  segment: number;
  subsections: Section[];
  progress: FormSubmitProgress;
  sidebar_content: SidebarContent;

  repeater?: boolean;
  title?: string;
  min?: number;
  max?: number;
}

export interface Section {
  _id: string;
  name: string;
  segment: number;
  form: Form;
  _form_data: {
    form_data: Form;
  };
  progress: FormSubmitProgress;
  subsections?: Section[];
  title?: string;
  repeater?: boolean;
  min?: number;
  max?: number;
  _sidebar_content?: SidebarContent; // dashboard451
  sidebar_content: SidebarContent; // app451
  index_weight: number;
  'action-title'?: string;
  'item-title'?: string;
  'empty-description'?: string;
  'item-description'?: string;
}

export type Form = IFormData;
export type FormSubmitItem = FormValue;
export type FieldValue = FormsApi.FieldValue;
export interface FormEntry extends FormsApi.FieldValue {
  weight?: number;
}

export interface FormSubmitResponse {
  done: boolean;
  progresses: ResponseProgresses;
  info: FormEntry[];
}

export interface ResponseProgresses {
  application: FormSubmitProgress;
  sections: FormSubmitProgress[];
}

export interface FileRemoveResponse {
  done: boolean;
  progresses: ResponseProgresses;
  // it's actually the name of the field
  filename: string;
}

export interface DeleteRepeaterItemResponse {
  done: boolean;
  progresses: ResponseProgresses;
}

export interface UserApplication {
  guid: string;
  name: string;
  registration_id: string;
  term: GuidAndName;
  major: GuidAndName;
  last_login: string;
  progress: Progress;
  decision_status: DecisionStatusType;
  registered_at: string;
  // This is a workaround so that when there is no decision object
  // we can decide what is the status of the application
  // https://element451.atlassian.net/browse/LUM-8519
  status: ApplicationState;
  icon: string;
}

export interface GuidAndName {
  guid: string;
  name: string;
}

export interface Progress {
  status: string;
  guid: string;
  numeric_progress: number;
  count: ProgressCount;
}

export interface FormSubmitProgress extends Exclude<Progress, 'guid'> {
  progress_guid: string;
  subsections?: FormSubmitProgress[];
}

export interface ProgressCount {
  total_fields: number;
  missing_fields: number;
  completed_fields: number;
  total_repeaters: number;
  missing_repeaters: number;
  scope_total_inputs: number;
  scope_completed_inputs: number;
}

export interface DashboardSection {
  _id: string;
  name: string;
  progress: FormSubmitProgress;
  index_weight: number;
}

export enum ApplicationState {
  Started = 'started',
  Submitted = 'submitted',
  Completed = 'completed'
}

export interface ApplicationStatus {
  numeric_progress: number;
  registered_at: string;
  status: ApplicationState;
  decision_status: DecisionStatusType;
  status_changed_at: string;
  updated_at: string;
  submitted: boolean;
  submitted_at: string;
}

export type DecisionStatusType =
  | DecisionStatusSubmitted
  | DecisionStatusInReview
  | DecisionStatusAdmitted
  | DecisionStatusDenied
  | DecisionStatusConditionalOffer
  | DecisionStatusDeferred
  | DecisionStatusWaitlisted
  | DecisionStatusNotSubmitted
  | DecisionStatusInProgress
  | DecisionStatusWithdrawn
  | DecisionStatusToReview;

export interface BaseDecisionStatus<T = ApplicationStatusType> {
  name: string;
  slug: T;
  decision_made_at?: string;
  review_starts_at?: string;
  status_properties?: {
    name: string;
    slug: string;
  };
}

export type DecisionStatusSubmitted =
  BaseDecisionStatus<ApplicationStatusType.Submitted>;

export type DecisionStatusNotSubmitted =
  BaseDecisionStatus<ApplicationStatusType.NotSubmitted>;

export type DecisionStatusDenied =
  BaseDecisionStatus<ApplicationStatusType.Denied>;

export type DecisionStatusConditionalOffer =
  BaseDecisionStatus<ApplicationStatusType.ConditionalOffer>;

export type DecisionStatusAdmitted =
  BaseDecisionStatus<ApplicationStatusType.Admitted>;

export type DecisionStatusInReview =
  BaseDecisionStatus<ApplicationStatusType.InReview>;

export type DecisionStatusDeferred =
  BaseDecisionStatus<ApplicationStatusType.Deferred>;

export type DecisionStatusWaitlisted =
  BaseDecisionStatus<ApplicationStatusType.Waitlisted>;

export type DecisionStatusInProgress =
  BaseDecisionStatus<ApplicationStatusType.InProgress>;

export type DecisionStatusToReview =
  BaseDecisionStatus<ApplicationStatusType.ToReview>;

export type DecisionStatusWithdrawn =
  BaseDecisionStatus<ApplicationStatusType.Withdrawn>;

export enum ApplicationStatusType {
  InProgress = 'in-progress',
  NotSubmitted = 'not-submitted',
  Submitted = 'submitted',
  InReview = 'in-review',
  ToReview = 'to-review',
  Denied = 'decided-denied',
  Admitted = 'decided-admitted',
  ConditionalOffer = 'decided-offer',
  Deferred = 'decided-deferred',
  Waitlisted = 'decided-waitlisted',
  Withdrawn = 'decided-withdrawn'
}

export interface Dashboard {
  default_view_app: ApplicationViewType;
  decision_confirmation: {
    active: boolean;
  };
  description: string;
  guid: string;
  hero: Hero[];
  info_blocks: InfoBlock[];
  info_request: {
    title: string;
    show: boolean;
    required: boolean;
  };
  completed_alert: boolean;
  payment: AppPayment;
  processing_payment?: string; // If payment is started but not processed yet, this will be timestamp
  deposit: DepositPayment;
  major: GuidAndName;
  name: string;
  progress: FormSubmitProgress;
  registration_id: string;
  sections: DashboardSection[];
  sidebar_content: SidebarContent;
  snap_app: boolean;
  status: ApplicationStatus;
  submit_form_guid: string;
  term: GuidAndName;
  submitted_status_text?: string;
  show_application_status?: boolean;
  show_documents_section?: boolean;
  preview_settings?: {
    hide_blanks: boolean;
  };
  international_students?: boolean;
}

export interface SidebarContent {
  section_title: string;
  info_blocks: SidebarContentInfoBlock[];
}

export interface SidebarContentInfoBlock {
  title: string;
  description: string;
  markdown: boolean;
}

export interface DashboardContent {
  info_blocks: InfoBlock[];
  hero: GreetingHero[];
}

export interface Hero {
  title: string;
  description: string;
  label?: string;
  status: HeroStatus;
}

export interface PositiveOutcomeHero extends Hero {
  status:
    | ApplicationStatusType.Admitted
    | ApplicationStatusType.ConditionalOffer;
  congratulations?: {
    title: string;
    subtitle: string;
    message: string;
    button_label: string;
    button_link: string;
    show_button: boolean;
  };
}

export enum GreetingStatusType {
  Default = 'default',
  Submitted = 'submitted',
  InReview = 'in-review',
  Admitted = 'admitted',
  Denied = 'denied',
  ConditionalOffer = 'conditional-offer',
  Deferred = 'deferred',
  Waitlisted = 'waitlisted'
}

export interface GreetingHero extends Omit<Hero, 'status'> {
  status: GreetingStatusType;
  congratulations?: {
    title: string;
    subtitle: string;
    message: string;
    show_button: boolean;
    button_link: string;
    button_label: string;
  };
}

enum _HeroStatus {
  Default = 'default',
  // TODO: BE sets this as waitlisted status on hero, but should be decision-wailisted
  Waitlisted = 'waitlisted'
}

export type HeroStatus = ApplicationStatusType | _HeroStatus;

export type InfoBlock =
  | TextInfoBlock
  | DeadlineInfoBlock
  | DocumentInfoBlock
  | ChecklistInfoBlock;

export enum InfoBlockType {
  General = 'general',
  Deadline = 'deadline',
  Checklist = 'checklist',
  Documents = 'documents'
}

interface BaseInfoBlock {
  title: string;
  _id: string;
  index_weight?: number;
  conditions?: FormsApi.FieldConditionals[];
  conditionals_operator?: ConditionalOperator;
}

export interface TextInfoBlock extends BaseInfoBlock {
  type: InfoBlockType.General;
  markdown: boolean;
  description: string;
}

export interface DeadlineInfoBlock extends BaseInfoBlock {
  type: InfoBlockType.Deadline;
  dates: DeadlineDate[];
}

export interface DeadlineDate {
  date: string;
  important: boolean;
  title: string;
}

export interface DocumentInfoBlock extends BaseInfoBlock {
  type: InfoBlockType.Documents;
}

export interface ChecklistInfoBlock extends BaseInfoBlock {
  type: InfoBlockType.Checklist;
}

export enum InfoRequestStatus {
  Submitted = 'Submitted',
  Pending = 'Pending'
}

export interface InformationRequest {
  // guid: string;
  title: string;
  description: string;
  description_optional: string;
  // items:  any[];
  sidebar_content: SidebarContent;
  // user_info_requests: UserInfoRequest[];
  // student_forms: RequestForm[];
  request_types: RequestType[];
}

export interface InformationBlock {
  title: string;
  markdown: boolean;
  description: string;
}

export interface UserInfoRequest {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  status: InfoRequestStatus;
  application_guid: string;
  info_request_name: string;
  _info_request_guid: string;
  _form_send_to_guid: string;
  _form_request_info_guid: string;
  submitted_at: string;
  request_type: string;
  waive: boolean;
  _locker_key: string;
  multipurpose: boolean;
  job_title: string;
  recommender_email: string;
  phone: {
    'phone-type': 'home';
    'phone-country_alpha_2': string;
    'phone-country_code': string;
    'phone-number': string;
  };
}

export interface RequestType {
  guid: string;
  name: string;
  index_weight: number;
  max: number;
  min: number;
  filters: RequestTypeFilter[];
  student_form: RequestForm;
}

export interface WithCriteria<T> {
  criteria: {
    conditions: T[];
    operator: string; // TODO: add all operator types ($eq, $ne...)
  };
}

export interface RequestTypeFilter
  extends WithCriteria<WithCriteria<RequestTypeFilterCriteriaCondition>> {
  actions: RequestTypeFilterActions;
}

export interface RequestTypeFilterActions {
  info_request_max: number;
  info_request_min: number;
}

export interface RequestTypeFilterCriteriaCondition {
  namespace: string;
  scope: 'application';
  target: string;
  value: any;
}

export interface RequestForm {
  _form: string;
  description: string;
  'waiver-label': string;
  preview_description: string;
  'preview-description_success': string;
  fields: FormsApi.FieldValue[];
}

export interface RequestFile extends ApiFile {
  access_token: string;
  author: string;
  bucket: string;
  created_at: string;
  /**
   * Which field has uploaded this file
   */
  field_name: string;
  from_data: {
    app_guid: string;
    form_guid: string;
  };
  from_source: string;
  path: string;
  public: boolean;
}

export interface RecommedationDocuments {
  data: {
    [key: string]: string;
  };
  files: RequestFile[];
}

export interface Recommendation {
  application_guid: string;
  first_name: string;
  last_name: string;
  email: string;
  documents?: RecommedationDocuments;
  status: InfoRequestStatus;
  status_submitted: string;
  submitted: string;
  waive: boolean;

  index_weight: number;
  info_request_name: string;
  multipurpose: boolean;
  recommender_email: string;
  registration_id: string;
  request_type: string;

  _form_request_info: string;
  _form_send_to: string;
  _id: string;
  _info_request: string;
}

export interface InfoRequest {
  request_info: {
    notification_guid: string;
    _form: string;
    'confirmation-description': string;
    'confirmation-title': string;
    'form-title': string;
    'form-title-submitted': string;
    'greeting-description': string;
    'greeting-description-submitted': string;
    'greeting-title': string;
    'greeting-title-submitted': string;
    'waiver-label': string;
  };
  send_to: {
    description: string;
    notification_guid: {
      with_waiver: string;
      without_waiver: string;
    };
    'preview-description-success': string;
    preview_description: string;
    'waiver-label': string;
    _form: string;
  };
}

export interface LoginInfo {
  application_guid: string;
  applicationGuid: string;
  registrationId: string;
  first_name: string;
  last_name: string;
  email: string;
  fastApplication: false;
  incompleteRegistration: boolean;
  info_request: InfoRequest;
  otherApplications: string[];
  recommendation: Recommendation;
  recommender_form: FormsApi.Field[];
  request_type: string;
  subdom: string;
  userType: string;
  usageScope: LockerScope;
  origin: string;
  _form_request_info: string;
  _form_send_to: string;
  _info_request: string;
}

export enum LockerScope {
  Student = 'student_login',
  Recommender = 'recommender_login'
}

export interface RequestLocker extends AuthApi.Account {
  loginInfo: LoginInfo;
}

export interface AddRequestData {
  values: {
    fields: { name: string; value: string }[];
  };
  application_guid: string;
  request_guid: string;
  form_guid: string;
}

export interface CreateRequestData {
  values: {
    waive: boolean;
    [key: string]: any;
  };
  application_guid: string;
  form_guid: string;
  request_guid: string;
}

export interface SnapAppResponse {
  sections: Step[];
}

export interface FieldSlugNameMappingsResponse {
  [slug: string]: string;
}

export enum ChecklistItemStatus {
  Completed = 'completed',
  Waived = 'waived',
  Uncompleted = 'uncompleted'
}

export interface ChecklistItem {
  _id: string;
  item_key: string;
  title: string;
  description: string;
  status: ChecklistItemStatus;
  type: 'custom' | 'condition';
  required: boolean;
  index_weight: number;
  completed_by: 'user';
  completed_by_user: {
    _id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  completed_at: string;
  status_changed_at: string;
  post_admit?: boolean;
}

export enum DocumentFileType {
  UserUpload = 'user_upload',
  InfoRequestUpload = 'info_request_upload',
  InfoRequestPreview = 'info_request_preview',
  InfoRequestOverview = 'info_request_overview',
  UserApplicationOverview = 'user_application_overview',
  UserApplicationPreview = 'user_application_preview',
  ApplicationSubmissionSnapshot = 'application_submission_snapshot',
  UserSupplementalFormSubmission = 'user_supplemental_form_submission'
}

export interface DocumentFile {
  category: DocumentFileType;
  guid: string;
  name: string;
  // Human Readable Name
  alt_name: string;
  mime_type: FilesApi.MimeType;
  size: number;
  url: string;
  created_at: string;
  document?: {
    /** this is probably redundant */
    guid: string;
    type: {
      name: string;
      slug: string;
      icon: string;
      guid: string;
    };
  };
  derived_files?: {
    document_jpg?: {
      url: string;
      preview_url: string;
    };
  };
}

export interface UserDocument extends FormsApi.Field {
  form_guid: string;
  upload: DocumentFile | DocumentFile[];

  // repeater document
  parent_field_slug?: string;
  parent_field_name?: string;
  is_subfield?: boolean;
  item_id?: string;
  in_repeater_section?: boolean;
  repeater_item?: {
    ceeb?: string;
    city?: string;
    country?: string;
    county?: string;
    name?: string;
    state?: string;
    street_1?: string;
    street_2?: string;
    zipcode?: string;
  };
}

export interface SupplementalFormListItem {
  guid: string;
  name: string;
  description?: string;
  author_id: string;
}

export interface SupplementalForm extends SupplementalFormListItem {
  fields: FormsApi.Field[];
  generate_pdf?: boolean | null;
  document_type_guid?: string | null;
}

export type SupplementalFormDto = Pick<
  SupplementalForm,
  'name' | 'description' | 'fields' | 'generate_pdf' | 'document_type_guid'
>;

export enum SupplementalFormsStepConditionType {
  UserSegmentRef = 'user_segment_reference',
  UserSegment = 'user_segment'
}

export interface SupplementalFormsStepCondition {
  type: SupplementalFormsStepConditionType;
  content: any;
}

export type SupplementalFormConditionOperators = `and` | `or`;
export type SupplementalFormConditionBelongingOperators = `in` | `nin`;

export interface SupplementalFormsStep {
  item_id: string;
  title: string;
  index_weight: number;
  description?: string;
  supplemental_form_guid: string;
  conditions_operator: SupplementalFormConditionOperators;
  conditions_belonging_operator: SupplementalFormConditionBelongingOperators;
  conditions?: SupplementalFormsStepCondition[];
  sidebar_content: SidebarContent;
}

export type SupplementalFormsStepDto = Omit<
  SupplementalFormsStep,
  'item_id' | 'index_weight'
>;

export enum SupplementalFormStatus {
  Done = 'done',
  Pending = 'pending'
}

export type PublicSupplementalFormsStepListItem = Pick<
  SupplementalFormsStep,
  | 'item_id'
  | 'title'
  | 'description'
  | 'index_weight'
  | 'supplemental_form_guid'
> & { status: SupplementalFormStatus };

export interface SupplementalFormData extends FormsApi.FormData {
  _id: string;
  updated_at: string;
  created_at: string;
}

export interface SupplementalFormInfo {
  name: string;
  description: string;
  field_guid: string;
  guid: string; // "tu.supplementalforms.2"
}

export type SupplementalFormUserData = Record<
  string,
  string | Record<string, string | null> | null
>;

export interface PublicSupplementalFormsStep
  extends PublicSupplementalFormsStepListItem {
  sidebar_content: SupplementalFormsStep['sidebar_content'];
  form: SupplementalFormInfo;
  field: {
    form_data: SupplementalFormData;
    user_data: SupplementalFormUserData;
  };
  answer?: SupplementalFormAnswer;
}

export interface SupplementalFormCapturePayload {
  fields: { name: string; value: string }[];
  files?: { name: string; file: File }[];
}

export interface SupplementalFormAnswer {
  item_id: string;
  app_item_id: string;
  application_guid: string;
  registration_id: string;
  supplemental_form_guid: string;
  submitted_at: string;
}

export interface SupplementalFormAnswerResponse extends SupplementalFormAnswer {
  info: FormsApi.FieldValue[];
}

export const removeAppScopeTokenParams = (tokens: TokensApi.Token[]) => {
  const blacklistedTokenParams = new Set(['app_guid', 'application_guid']);
  const groupsToCheck = new Set([
    TokensApi.TokenGroup.Application,
    TokensApi.TokenGroup.UserApplication,
    TokensApi.TokenGroup.UserDecision
  ]);

  return tokens.map(token => {
    if (
      groupsToCheck.has(token.group) &&
      token.parameters.some(p => blacklistedTokenParams.has(p.key))
    ) {
      return {
        ...token,
        parameters: token.parameters.filter(
          p => !blacklistedTokenParams.has(p.key)
        )
      };
    }
    return token;
  });
};
